import cn from 'clsx'
import React from 'react'

type Props = {
  onClick: () => any;
  className?: string;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

function Button({
  onClick,
  className = '',
  children,
  type = 'button',
  disabled = false,
}: Props) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(
        'whitespace-nowrap',
        'inline-flex',
        'bg-blue',
        'text-gray-500',
        'hover:text-gray-900',
        'p-2',
        'rounded',
        'uppercase',
        'text-sm',
        'font-medium',
        {
          [className]: Boolean(className),
        }
      )}
    >
      {children}
    </button>
  )
}
export default Button
