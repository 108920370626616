import { hasData } from "./hasData";

export function getAttrFromJson(json,attr,value) {

    try {
        if (!hasData(json[attr])) return null;
        let fieldAttr = JSON.parse(json[attr]);
        if (!Array.isArray(fieldAttr)) return null;
        return JSON.parse(json[attr])[0][value];

    } catch (e) {
        return null;
    }

    
    
}