import { Fragment } from "react";
import TimestampForm from "../profile/timestamp/timestamp-form"

import { Dialog, Transition } from "@headlessui/react";

export default function PlayerModal({
    modal_panel,
    isModalOpen,
    clock,
    set_clock,
    closeModal,
    sendmsg,
    channel_id,
    host_id,
    post_id,
    content_id
}) {



    return (<>
        <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
        as="div"
        open={isModalOpen}
        className="fixed inset-0  z-[2000] overflow-y-scroll bg-[rgb(0,0,0,0.85)]"
        onClose={closeModal}
        >
        
        <div className="min-h-screen px-4 text-center">
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <Dialog.Overlay className="fixed inset-0 " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
            >
            &#8203;
            </span>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            >
            <div className=" inline-block max-w-3xl w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                            bg-gray-900 border-gray-700">
                
                <div className="items-center content-center">
                    <div className="flex-1">

                    {/* TIMESTAMP */}
                    {(["timestamp"]?.indexOf(modal_panel?.id) > -1) &&
                    <>
                    <TimestampForm
                        clock={clock}
                        set_clock={set_clock}
                        closeModal={closeModal}
                        sendmsg={sendmsg}
                        channel_id={channel_id}
                        live_chat={false}
                        host_id={host_id}
                        post_id={post_id}
                        content_id={content_id}
                        fetchurl={post_id == 0 
                                    ? `/api/private/timestamp/insert-chat` 
                                    : `/api/private/timestamp/insert`}
                     />
                    </>
                    }


                    </div>
                   
                </div>
               
            </div>
            </Transition.Child>
        </div>
        </Dialog>
    </Transition>


</>)
}