import { hasData } from "@/lib/utils/hasData";
import { Fragment } from "react";
import FormInput from "../form-input/form-input";
import WizardSection from "./wizard-section";

export default function WizardSectionForm({
    section_id
   ,wizard_id
   ,set_wizard_id
   ,wizard_array
   ,submitting
   ,set_submitting
   ,processing
   ,set_processing
   ,changes
   ,set_changes
   ,children
  }) {
   return (
    <WizardSection
      section_id={section_id}
      wizard_id={wizard_id}
      set_wizard_id={set_wizard_id}
      wizard_array={wizard_array}
    >
     
     <>
     {wizard_array[section_id]?.fields?.map((e,index)=> 
      <Fragment 
      key={index}
      >

          <FormInput 
            type={e?.type}
            title={e?.title}
            description={e?.description}
            helptip={e?.helptip}
            name={e?.name}
            placeholder={e?.placeholder}
            options={e?.options}
            
            value={e?.value}
            set_value={e?.set_value}
            hidden_value={e?.hidden_value}
            set_hidden_value={e?.set_hidden_value}
            meta={e?.meta}
            show_condition={e?.show_condition}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
            processing={processing}
            set_processing={set_processing}
          />
          
         
      
         
    </Fragment>
    )}
    <>
      {children}
    </>
    </>
   </WizardSection>)
  }
  
  