
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import WizardHeader from "@/components/templateux/wizard/wizard-header";
import isAdminUser from "@/lib/utils/isAdminUser";
import WizardSectionForm from "@/components/templateux/wizard/wizard-section-form";
import WizardOwner from "@/components/templateux/wizard/wizard-owner";
import PostFormType from "./post-form-type";
import { sluginput } from "@/lib/utils/slugify";
import WizardPager from "@/components/templateux/wizard/wizard-pager";
import { extractDateObject, isoToMysqlDateStr, isostringifyDateObject, timezoneIsostringifyDateObject } from "@/lib/utils/extractTimeObject";
import { truncate } from "@/lib/utils/truncate";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { ifNull } from "@/lib/utils/ifNull";
import { config, tablehelptype } from "@/lib/config";
import moment from 'moment-timezone'

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //post info
  post_id?: any;
  itemdata?: any;
  parentdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  postcache?:any;
  set_postcache?:any;
  typevalue?:any;
  set_typevalue?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;
  
  clip_state?: any;
  clip_json?: any;
}

function PostForm({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,processing
  ,set_processing
  ,set_changes
  ,changes
  ,max_files

  //post info
  ,post_id
  ,itemdata
  ,parentdata
  ,itemdataisLoading
  ,itemdataisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,postcache
  ,set_postcache
  ,typevalue
  ,set_typevalue

  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype

  ,clip_state="0"
  ,clip_json=[]
}: Props) {


  const [ispageloaded,set_ispageloaded] = useState(false)
  useEffect(()=>{set_ispageloaded(true)},[])
 
  const [admin_user_name,set_admin_user_name] = useState(!itemdataisLoading 
                                                          && !itemdataisError 
                                                          && itemdata 
                                                          && ifNull(itemdata?.user_name,ownerdata?.user_name))
  const [bol_admin_user_name,set_bol_admin_user_name] = useState("0")
  const [user_id,set_user_id] = useState(userdata?.id)
  
  const [_post_type, set_post_type] = useState(ifNull(itemdata?.post_type,typevalue))
  const [_post_title, set_post_title] = useState(ifNull(itemdata?.post_title,''))
  const [_post_body, set_post_body] = useState(ifNull(itemdata?.post_body,''))
  const [_post_tags, set_post_tags] = useState(ifNull(itemdata?.post_tags,''))
  const [_bol_public, set_bol_public] = useState(ifNull(itemdata?.bol_public,'1'))
  const [_publish_date, set_publish_date] = useState(hasData(itemdata?.publish_date) 
                                                      ? extractDateObject(new Date(itemdata?.publish_date)?.toISOString()) 
                                                      : extractDateObject(new Date()?.toISOString()))
  const [_publish_date_original, set_publish_date_original] = useState(hasData(itemdata?.publish_date_original) 
                                                      ? extractDateObject(new Date(itemdata?.publish_date_original)?.toISOString()) 
                                                      : extractDateObject(new Date()?.toISOString()))
  const [_publish_date_timezone, set_publish_date_timezone] = useState(hasData(itemdata?.publish_date_timezone) 
                                                      ? itemdata?.publish_date_timezone
                                                      : hasData(moment.tz.guess())
                                                        ? moment.tz.guess()
                                                        : config.form.defaulttimezone)
  const [_bol_expire, set_bol_expire] = useState(ifNull(itemdata?.bol_expire,'0'))
  const [_expire_date, set_expire_date] = useState(hasData(itemdata?.expire_date) 
                                                      ? extractDateObject(new Date(itemdata?.expire_date)?.toISOString()) 
                                                      : extractDateObject(new Date()?.toISOString()))
  const [_expire_date_original, set_expire_date_original] = useState(hasData(itemdata?.expire_date_original) 
                                                      ? extractDateObject(new Date(itemdata?.expire_date_original)?.toISOString()) 
                                                      : extractDateObject(new Date()?.toISOString()))
  const [_expire_date_timezone, set_expire_date_timezone] = useState(hasData(itemdata?.expire_date_timezone) 
                                                      ? itemdata?.expire_date_timezone
                                                      : hasData(moment.tz.guess())
                                                        ? moment.tz.guess()
                                                        : config.form.defaulttimezone)
  const [_bol_notification, set_bol_notification] = useState(ifNull(itemdata?.bol_notification,'0'))
  const [_bol_comments, set_bol_comments] = useState(ifNull(itemdata?.bol_comments,'1'))
  const [_bol_likes, set_bol_likes] = useState(ifNull(itemdata?.bol_likes,'1'))
  const [_access_level, set_access_level] = useState(ifNull(itemdata?.access_level,'1'))
  const [_slug, set_slug] = useState(ifNull(itemdata?.slug,''))
  const [_post_description, set_post_description] = useState(ifNull(itemdata?.post_description,''))
  const [_post_contents_json, set_post_contents_json] = useState(ifNull(itemdata?.post_contents_json,'[]'))
  const [_bol_sticky, set_bol_sticky] = useState(ifNull(itemdata?.bol_sticky,'0'))
  const [_bol_featured, set_bol_featured] = useState(ifNull(itemdata?.bol_featured,'1'))
  const [_bol_transcribe, set_bol_transcribe] = useState(ifNull(itemdata?.bol_transcribe,'1'))
  const [_bol_replay_post, set_bol_replay_post] = useState(ifNull(itemdata?.bol_replay_post,'1'))
  
  
  useEffect(()=>{


    if (form_reset==1 
      && ((
          hasData(post_id) 
          && post_id.toString() !== "0" 
          && !itemdataisLoading && !itemdataisError
          )
        )
      ) {

      set_admin_user_name(hasData(itemdata?.user_name) 
                          ? itemdata?.user_name 
                          : userdata?.user_name)

        set_post_type(hasData(itemdata?.post_type)
                                    ? itemdata?.post_type 
                                    : typevalue
                                  )

        set_post_title(hasData(itemdata?.post_title)
                                    ? itemdata?.post_title 
                                    : ''
                                  )
        set_post_body(hasData(itemdata?.post_body) 
                                    ? itemdata?.post_body 
                                    : ''
                                    )
        set_post_tags(hasData(itemdata?.post_tags)
                                    ? itemdata?.post_tags 
                                    : ''
                                  )

      set_bol_public(hasData(itemdata?.bol_public) 
                                  ? itemdata?.bol_public 
                                  : '1'
                                  )
      set_publish_date(hasData(itemdata?.publish_date) 
                                                          ? extractDateObject(new Date(itemdata?.publish_date)?.toISOString())
                                                          : extractDateObject(new Date()?.toISOString())
                                                          )

      set_publish_date_original(hasData(itemdata?.publish_date_original) 
                                                          ? extractDateObject(new Date(itemdata?.publish_date_original)?.toISOString()) 
                                                          : extractDateObject(new Date()?.toISOString()))
      set_publish_date_timezone(hasData(itemdata?.publish_date_timezone) 
                                                          ? itemdata?.publish_date_timezone
                                                          : hasData(moment.tz.guess())
                                                            ? moment.tz.guess()
                                                            : config.form.defaulttimezone)
      set_bol_expire(hasData(itemdata?.bol_expire) 
                                  ? itemdata?.bol_expire 
                                  : '0'
                                  )
      set_expire_date(hasData(itemdata?.expire_date) 
                                                          ? extractDateObject(new Date(itemdata?.expire_date)?.toISOString())
                                                          : extractDateObject(new Date()?.toISOString())
                                                          )

      set_expire_date_original(hasData(itemdata?.expire_date_original) 
                                                          ? extractDateObject(new Date(itemdata?.expire_date_original)?.toISOString()) 
                                                          : extractDateObject(new Date()?.toISOString()))
      set_expire_date_timezone(hasData(itemdata?.expire_date_timezone) 
                                                          ? itemdata?.expire_date_timezone
                                                          : hasData(moment.tz.guess())
                                                            ? moment.tz.guess()
                                                            : config.form.defaulttimezone)



        set_bol_notification(hasData(itemdata?.bol_notification) 
                                    ? itemdata?.bol_notification 
                                    : '0'
                                    )

        set_bol_comments(hasData(itemdata?.bol_comments) 
                                    ? itemdata?.bol_comments 
                                    : '1'
                                    )

        set_bol_likes(hasData(itemdata?.bol_likes) 
                                    ? itemdata?.bol_likes 
                                    : '1'
                                    )

        set_access_level(hasData(itemdata?.access_level) 
                                  ? itemdata?.access_level 
                                  : '1')

        set_slug(hasData(itemdata?.slug) 
                                  ? itemdata?.slug 
                                  : '')

        set_post_description(hasData(itemdata?.post_description) 
                                  ? itemdata?.post_description 
                                  : '')

        if (clip_state?.status?.toString()=="0" || !hasData(clip_state)) {
          
          set_post_contents_json(hasData(itemdata?.post_contents_json) 
                                  ? itemdata?.post_contents_json 
                                  : '[]')
        }

        set_bol_sticky(hasData(itemdata?.bol_sticky) 
                                    ? itemdata?.bol_sticky 
                                    : '0'
                                    )

        set_bol_featured(hasData(itemdata?.bol_featured) 
                                    ? itemdata?.bol_featured 
                                    : '1'
                                    )

        set_form_reset(0) //make sure we dont remove the data again now that it's filled in.
    
    }
 
},[
   itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,post_id
  ,changes
  ,form_reset
  ,set_form_reset
  ,typevalue
  ,userdata
  ,clip_state
])


useEffect(()=> {

  if (clip_json?.length > 0) {
    set_post_contents_json(JSON.stringify([{
      url: clip_json[0]?.url,
      hash: clip_json[0]?.hash,
      size: clip_json[0]?.size,
      crdate: clip_json[0]?.crdate,
      source: clip_json[0]?.source,
      duration: clip_json[0]?.duration,
      filename: clip_json[0]?.filename,
      extention: clip_json[0]?.extention,
      post_type: clip_json[0]?.post_type,
      upload_id: clip_json[0]?.upload_id,
      metadata_json: clip_json[0]?.metadata_json,
      clip_start: clip_json[0]?.clip_start,
      clip_end: clip_json[0]?.clip_end
    }]))
  }

},[
  clip_json
])


  const { isConfirmed } = useConfirm();

  async function reset_post () {
    if (JSON.parse(_post_contents_json)?.length > 0) {
      const confirmed = await isConfirmed("Changing your post will reset all your attachments. Are you sure?");
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
        set_post_contents_json('[]')
      }
    }
  }

  function slugformat(value) {
    if (value?.length > 0) {
      set_slug(truncate(sluginput(post_id,value,value).replace(`${post_id}-`,''),25,'')?.replace(`Untitled`,'')?.toLowerCase())
    } else {
      set_slug('')
    }
  }


  // var outputx = new Date(
  //   moment(
  //     isostringifyDateObject(_publish_date_original)
  //   ).add(
  //     new Date(
  //                             new Date(itemdata?.publish_date)?.toLocaleString(
  //                               "en-US",{timeZone: _publish_date_timezone}
  //                             )
  //                           )?.getTimezoneOffset(),
  //      'minutes'
  //     ).toDate()
  //   )?.toISOString()

  // console.log("outputx",isostringifyDateObject(_publish_date_original),outputx)

  const [wizard_id,set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title:'Post content'
      ,description:''
      ,short: 'content'
      ,fields: [
                // {
                //    type:'selectbox'
                //   ,title:'Post Type'
                //   ,description:''
                //   ,helptip:''
                //   ,name:'post_type'
                //   ,placeholder:''
                //   ,options:[
                //     lookupPost("text",-1),
                //     lookupPost("image",-1),
                //     lookupPost("audio",-1),
                //     lookupPost("mic",-1)
                //   ]
                //   ,value:_post_type
                //   ,set_value:set_post_type
                //   ,hidden_value:_post_contents_json
                //   ,set_hidden_value:set_post_contents_json
                // },
                {
                  type:'text'
                  ,title:'Post Title'
                  ,description:''
                  ,helptip:''
                  ,name:'post_title'
                  ,placeholder:''
                  ,value:_post_title
                  ,set_value:set_post_title
                },
                
                
                 ["14"].indexOf(_post_type?.toString()) > -1 
                  ? {
                    type: 'text'
                    ,title:'Video URL'
                    ,description:'Enter the URL of a video (eg. from YouTube)'
                    ,helptip:'We support media from YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura.'
                    ,name:'post_body'
                    ,placeholder:'https://www.youtube.com/watch?v=abcdefghijkl'
                    ,value:_post_body
                    ,set_value:set_post_body
                    }
                  : {
                    type: 'textarea-autosize'
                    ,title:'Post Description'
                    ,description:''
                    ,helptip:''
                    ,name:'post_body'
                    ,placeholder:''
                    ,value:_post_body
                    ,set_value:set_post_body
                  },
                {
                  type:'text'
                  ,title:'Post Tags'
                  ,description:'separate tags with commas'
                  ,helptip:''
                  ,name:'post_tags'
                  ,placeholder:''
                  ,value:_post_tags
                  ,set_value:set_post_tags
                }
                
              ]
    }
    // ,{
    //   title:'Scheduling'
    //   ,description:''
    //   ,short: 'scheduling'
    //   ,fields: [
    //     // {
    //     //   type:'textarea-autosize'
    //     //   ,title:'Post Contents Json'
    //     //   ,description:''
    //     //   ,helptip:''
    //     //   ,name:'post_contents_json'
    //     //   ,placeholder:''
    //     //   ,value: JSON.stringify(_post_contents_json)
    //     //   ,set_value:set_post_contents_json
    //     // },
    //     {
    //       type:'date'
    //       ,title:'Publish Date'
    //       ,description:''
    //       ,helptip:''
    //       ,name:'publish_date'
    //       ,placeholder:''
    //       ,value:_publish_date
    //       ,set_value:set_publish_date
    //     }
    //   ]
    // }
    ,{
      title:'Advanced'
      ,description:'Only mess with these settings if you are a professional internet surfer.'
      ,short: 'advanced'
      ,fields: [

        // {
        //   type:'select'
        //   ,title:'Access Level'
        //   ,description:''
        //   ,helptip:''
        //   ,name:'access_level'
        //   ,placeholder:''
        //   ,options:[
        //     {
        //       text:'Public'
        //       ,value: '1'
        //     },
        //     {
        //       text:'Followers Only'
        //       ,value: '2'
        //     },
        //   ]
        //   ,value:_access_level
        //   ,set_value:set_access_level
        // },
        // {
        //   type:'text'
        //   ,title:'Customize your URL Ending'
        //   ,description:<div className='text-xs'>{ssr_data_tribe?.server_website}/{admin_user_name}/posts/{(post_id?.toString()=="0" ? "42069" : post_id?.toString())}-<span className='text-blue-400'>{sluginput((post_id==0 ? 42069 : post_id),_slug,_post_title)}</span></div>
        //   ,helptip:''
        //   ,name:'slug'
        //   ,placeholder:`${sluginput(post_id,_slug,_post_title).replace(`${post_id}-`,'')}`
        //   ,value:_slug
        //   ,set_value:slugformat
        //   ,meta:{maxChars:25}
        // },
        
        {
          type:'toggle'
          ,title:'Enable responses?  '
          ,description:''
          ,helptip:''
          ,name:'bol_comments'
          ,placeholder:''
          ,value:_bol_comments
          ,set_value:set_bol_comments
        },    
        ["5","2","13"].indexOf(_post_type?.toString()) > -1
        ? {
            type:'toggle'
            ,title:'Show transcript?'
            ,description:''
            ,helptip:'This shows the audio transcript, if available. May only apply to upgraded accounts.'
            ,name:'bol_transcribe'
            ,placeholder:''
            ,value:_bol_transcribe
            ,set_value:set_bol_transcribe
          } 
          : {type:'hide'}
        ,  
        ["5"].indexOf(_post_type?.toString()) > -1
        ? {
            type:'toggle'
            ,title:'Show chat replay?'
            ,description:''
            ,helptip:'This lets the chat be played back in real time with the show.'
            ,name:'bol_replay_post'
            ,placeholder:''
            ,value:_bol_replay_post
            ,set_value:set_bol_replay_post
          } 
          : {type:'hide'}
        ,                  
        // {
        //   type:'toggle'
        //   ,title:'Enable likes?  '
        //   ,description:''
        //   ,helptip:''
        //   ,name:'bol_likes'
        //   ,placeholder:''
        //   ,value:_bol_likes
        //   ,set_value:set_bol_likes
        // },  
        {
          type:'toggle'
          ,title:'Share to Public Feeds?'
          ,description:''
          ,helptip:'Enabling this will show your post in the public feeds. Disabling this will keep your posts on your profile page only.'
          ,name:'bol_featured'
          ,placeholder:''
          ,value:_bol_featured
          ,set_value:set_bol_featured
        },
        {
          type:'toggle'
          ,title:'Sticky post to profile?'
          ,description:''
          ,helptip:'Pin this post to the top of your other posts indefinitely. This is good for important announcements if you have an active account and do not want a post to be missed.'
          ,name:'bol_sticky'
          ,placeholder:''
          ,value:_bol_sticky
          ,set_value:set_bol_sticky
        },
        {
          type:'selectoptions'
          ,title:'Publish this Post?'
          ,description:''
          ,helptip:''
          ,name:'bol_public'
          ,placeholder:''
          ,value:_bol_public
          ,set_value:set_bol_public
          ,options:[
            {
              text:'Keep as a Private Draft'
              ,value: '0'
            },
            {
              text:'Publish now'
              ,value: '1'
            },
            {
            text:'Publish at a scheduled time'
            ,value: '2'
            ,premium_level: 1
            ,userdata: userdata
            },
          ]
        }
        ,{
           type:'date'
          ,title:'Publish Date'
          ,description: <>
          {/* {moment.tz(isostringifyDateObject(_publish_date_original),_publish_date_timezone).format("z")}
          {moment.tz(isostringifyDateObject(_publish_date_original),_publish_date_timezone).utcOffset()} ///// 
          UTC: {isoToMysqlDateStr(timezoneIsostringifyDateObject(_publish_date_original,_publish_date_timezone))}*/}
          </>
          ,helptip:''
          ,name:'publish_date_original'
          ,placeholder:''
          ,value:_publish_date_original
          ,set_value:set_publish_date_original
          ,show_condition:_bol_public==2
        }
        ,{
           type:'timezone'
          ,title:''
          ,description: 'Time Zone'
          ,helptip:''
          ,name:'publish_date_timezone'
          ,placeholder: config.form.defaulttimezone
          ,value:_publish_date_timezone
          ,hidden_value:_publish_date
          ,set_value:set_publish_date_timezone
          ,show_condition:_bol_public==2
        }
        ,
        {
          type:'selectoptions'
          ,title:`Automatically Expire this Post?`
          ,description:''
          ,helptip:''
          ,name:'bol_expire'
          ,placeholder:''
          ,value:_bol_expire
          ,set_value:set_bol_expire
          ,options:[
            {
              text:'No: Let it stay up until I remove it.'
              ,value: '0'
              ,recommended:'0'
            },
            {
            text:'Yes: Expire it at a scheduled time'
            ,value: '2'
            ,premium_level: 1
            ,userdata: userdata
            },
          ]
        }
        ,{
          type:'date'
         ,title:'Expiration Date'
         ,description: ''
         ,helptip:''
         ,name:'expire_date_original'
         ,placeholder:''
         ,value:_expire_date_original
         ,set_value:set_expire_date_original
         ,show_condition:_bol_expire==2
       }
       ,{
          type:'timezone'
         ,title:''
         ,description: 'Time Zone'
         ,helptip:''
         ,name:'expire_date_timezone'
         ,placeholder: config.form.defaulttimezone
         ,value:_expire_date_timezone
         ,hidden_value:_expire_date
         ,set_value:set_expire_date_timezone
         ,show_condition:_bol_expire==2
       }
       ,
        {
          type:'textarea-autosize'
          ,title:'Add a short summary of your post'
          ,description:'This will appear in RSS feeds or notifications'
          ,helptip:''
          ,name:'post_description'
          ,placeholder:''
          ,value:_post_description
          ,set_value:set_post_description
          ,meta:{minRows:2,maxRows:10}
        }
        ,{
          type:'toggle'
          ,title:'Notify your followers about this Post?(ONLY VISIBLE TO MILK) '
          ,description:''
          ,helptip:''
          ,name:'bol_notification'
          ,placeholder:''
          ,value:_bol_notification
          ,set_value:set_bol_notification
          ,show_condition:userdata?.id?.toString() == "1"
        }
      ]
    }
  ]



          



    const [isAdmin,set_isAdmin] = useState(isAdminUser(
       ownerdata?.id?.toString()          //user_id
      ,ownerdata?.bol_staff_help    //bol_staff_help
      ,ownerdata?.staff_json        //staff_json
      ,ownerdata?.bol_admin_help    //bol_admin_help
      ,userdata?.id?.toString()                            //my_id
      ,userdata?.bol_admin?.toString()              //global_admin
      ,tablehelptype('post')
  ))
   




  function handleSubmit(e) {

    e.preventDefault();


      const post_data = {
          post_id: post_id
        , post_parent_id: parentdata?.post_id
        , post_title: _post_title
        , post_body: _post_body
        , post_tags: _post_tags
        , publish_date: isoToMysqlDateStr(timezoneIsostringifyDateObject(_publish_date_original,_publish_date_timezone))
        , publish_date_original: isostringifyDateObject(_publish_date_original)
        , publish_date_timezone: _publish_date_timezone
        , bol_public: _bol_public
        , expire_date: isoToMysqlDateStr(timezoneIsostringifyDateObject(_expire_date_original,_expire_date_timezone))
        , expire_date_original: isostringifyDateObject(_expire_date_original)
        , expire_date_timezone: _expire_date_timezone
        , bol_expire: _bol_expire
        , access_level: _access_level
        , post_type: _post_type
        , slug: hasData(_slug) ? _slug : `${sluginput(post_id,_slug,_post_title).replace(`${post_id}-`,'')}`
        , post_description: _post_description
        , post_contents_json: _post_contents_json 
        , admin_help_user_id: user_id
        , bol_comments : _bol_comments
        , bol_transcribe : _bol_transcribe
        , bol_replay_post : _bol_replay_post
        , bol_likes: _bol_likes
        , bol_notification: _bol_notification
        , bol_sticky: _bol_sticky
        , bol_featured: _bol_featured
        , set_submitting
      } 



      submitHandler(post_data
      ,`/api/private/post/update`
      ,'POST'
      ,{
        'Content-Type': 'application/json',
      }
      ,'Your changes were saved'
    )
    set_changes(false);
    let cachedate = + new Date;
    set_postcache(cachedate?.toString())

    
  }
  

  return (
    <>
    {/* info COMPONENT */}
    <div className="w-full">

      

      <WizardHeader
        wizard_array={wizard_array}
        wizard_id={wizard_id}
        set_wizard_id={set_wizard_id}
        disable_mobileview={true}
      />
    
    {/* {clip_state?.status?.toString() == "2" &&
        <>
          <div className="mt-2 font-bold text-lg">
          Your clip is processing now. 
          </div>
          <div className="italic">
          Add a little more info about it and then continue to the next section.
          </div>
          <ul>
            <li className="list-disc ml-5">
              Clipped from: <Link href={`/${parentdata?.user_name?.toLowerCase()}/posts/${slugify(parentdata?.post_id,parentdata?.post_title)}`}>
                <a className="underline hover:no-underline" target="_blank">
                  {parentdata?.post_title}
                </a>
              </Link>
            </li>
          </ul>
          

        </>
    } */}

      {/* FORM */}
      <div>
      <form 
        className="space-y-6" 
        onSubmit={handleSubmit}
      >

        {/* {JSON.stringify(_publish_date)}
        <div>
        {isostringifyDateObject(_publish_date)}
        </div>*/}
        {/* <div>
          {_post_contents_json}
        </div>  */}
        {/* <div>
          {JSON.parse(_post_contents_json)?.length}
        </div> */}
       

        <WizardSectionForm
          section_id={0}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        ><>



        {(parseInt(_post_type) > 0 &&
        _post_type?.toString() !== "14") && 
        <div className="flex items-center content-center">
          <div className="flex-1">
            <h3 className="font-bold">
              Files
            </h3>
          </div>
         
          <div className="flex-0">
            {JSON.parse(_post_contents_json)?.filter((e)=>hasData(e?.url))?.length} of {max_files}
          </div>
        </div>
        }


        <div className={`${(_post_type?.toString() !== "14") ? "block" : "invisible"}`}>
          {JSON.parse(_post_contents_json)?.length > 0 &&
          JSON.parse(_post_contents_json)?.map((e,index)=>
          <Fragment
            key={index}
          >
            <PostFormType
                
                _post_type={_post_type}
                set_post_type={set_post_type}
                _post_contents_json={_post_contents_json}
                set_post_contents_json={set_post_contents_json}
                _post_body={_post_body}
                set_post_body={set_post_body}
                submitting={submitting}
                set_submitting={set_submitting}
                processing={processing}
                set_processing={set_processing}
                changes={changes}
                set_changes={set_changes}
                index={index}
                max_files={max_files}
            />
          </Fragment>
          )}

            {JSON.parse(_post_contents_json)?.length == 0 &&
            <>
            <PostFormType
              _post_type={_post_type}
              set_post_type={set_post_type}
              _post_contents_json={_post_contents_json}
              set_post_contents_json={set_post_contents_json}
              _post_body={_post_body}
              set_post_body={set_post_body}
              submitting={submitting}
              set_submitting={set_submitting}
              processing={processing}
              set_processing={set_processing}
              changes={changes}
              set_changes={set_changes}
              index={0}
              max_files={max_files}
            />
            </>
            }

          {hasData(JSON.parse(_post_contents_json)[JSON.parse(_post_contents_json)?.length-1]?.url) &&
          JSON.parse(_post_contents_json)?.length < max_files &&
          <div className='flex justify-center'>

          <div
            className=" px-4 py-2 bg-blue-600 text-white cursor-pointer rounded-md hover:bg-blue-500 font-bold"
            onClick={()=>
            {let temp_obj = JSON.parse(_post_contents_json)
            
              let temp_details = {post_type: _post_type}
              temp_obj.push(temp_details)
              set_post_contents_json(JSON.stringify(temp_obj))}
            }
          >
            add another file
          </div>
          </div>
          }
      </div>

      </></WizardSectionForm> 

        <WizardSectionForm
          section_id={1}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
          ><>
          
          </></WizardSectionForm> 

        <WizardSectionForm
          section_id={2}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        >



                <WizardOwner
                  isAdmin={isAdmin}
                  bol_admin_user_name={bol_admin_user_name}
                  set_bol_admin_user_name={set_bol_admin_user_name}
                  admin_user_name={admin_user_name}
                  set_admin_user_name={set_admin_user_name}
                  user_id={user_id}
                  set_user_id={set_user_id}
                  submitting={submitting}
                  set_submitting={set_submitting}
                  processing={processing}
                  set_processing={set_processing}
                  changes={changes}
                  set_changes={set_changes}
                  title={`Set a Post Author`}
                  description={``}
                />


    </WizardSectionForm>  

    <WizardPager
      wizard_id={wizard_id}
      set_wizard_id={set_wizard_id}
      submitting={submitting}
      changes={changes}
      actionText={actionText}
      wizard_array={wizard_array}
    />
 
        
      </form>
    </div>
    {/* SET referral FORM */}

    </div>
    {/* // referral SET COMPONENT */}
  </>
  )
}

export default PostForm



