
import { hasData } from '@/lib/utils/hasData';
import TextareaAutosize from 'react-textarea-autosize';
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { useState } from 'react';
import TimestampTags from './timestamp-tags';
import toast from 'react-simple-toasts';


export default function TimestampForm({
    clock,
    set_clock,
    closeModal,
    sendmsg,
    channel_id,
    live_chat,
    host_id,
    post_id,
    content_id,
    fetchurl=`/api/private/timestamp/insert-chat`
}) {



    const { isConfirmed } = useConfirm();

    const [tag_index,set_tag_index] = useState(1)
    const [tag1,set_tag1] = useState('')
    const [tag2,set_tag2] = useState('')
    const [tag3,set_tag3] = useState('')
    const [tag4,set_tag4] = useState('')
    const [tag5,set_tag5] = useState('')
    const [tag_other1,set_tag_other1] = useState('')
    const [tag_other2,set_tag_other2] = useState('')
    const [tag_other3,set_tag_other3] = useState('')
    const [tag_other4,set_tag_other4] = useState('')
    const [tag_other5,set_tag_other5] = useState('')
    const [show_other1,set_show_other1] = useState('')
    const [show_other2,set_show_other2] = useState('')
    const [show_other3,set_show_other3] = useState('')
    const [show_other4,set_show_other4] = useState('')
    const [show_other5,set_show_other5] = useState('')

    const [notes,set_notes] = useState('')

    const strPrep = (str) => {
       return hasData(str) ? ` ${str}` : ''
    }

    const strTag = (str,other) => {
        if (str == "other")  return hasData(other) ? ` -${other}` : ''
        return hasData(str) ? ` -${str}` : ''
     }

     const saveTimestamp = async (msgstr) => {

        var postbody = {
            msgstr: msgstr,
            post_id: post_id,
            host_id: host_id,
            content_id: content_id
        }

        fetch(fetchurl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(postbody),
          })
          .then((res) => res.json())
          .then((json) => {
              if (json) {
                  const { outcome} = json;
    
                  //Use the results to see if a message needs to be displayed
                  if (outcome == 1) toast(`Your timestamp has been saved!`, { time: 3000, className: '', clickable: true, clickClosable: false });
                  


                }
            })
    }
    
    return (<>

                               
                               
        <div  className="mt-5  items-center content-center">
            <div className="mr-1 flex flex-col">
                <div className="font-bold mb-2">Timestamp</div>
                <input
                    type="text"
                    value={clock}
                    onChange={(e)=>set_clock(e.target.value)}
                    className="w-full  bg-gray-700 text-white font-bold"
                />
            </div>
            <div className="mr-1 flex-1 flex flex-col mt-5 ">
                <div className="font-bold mb-2">Optional Notes</div>
                <TextareaAutosize
                    className="rounded shadow border resize-none  focus:shadow-outline w-full flex
                    bg-gray-700 text-white
                                "
                    id="notes"
                    name="notes"
                    value={notes}
                    onChange={(e)=>set_notes(e.target.value)}
                />
            </div>
            <div className="mr-1 flex-1 flex flex-col mt-5 ">
                <div className="font-bold mb-2">Optional Tags</div>


                <TimestampTags
                    tag={tag1}
                    set_tag={set_tag1}
                    show_other={show_other1}
                    set_show_other={set_show_other1}
                    tag_other={tag_other1}
                    set_tag_other={set_tag_other1}
                    tag_index={tag_index}
                    id={1}
                />
                <TimestampTags
                    tag={tag2}
                    set_tag={set_tag2}
                    show_other={show_other2}
                    set_show_other={set_show_other2}
                    tag_other={tag_other2}
                    set_tag_other={set_tag_other2}
                    tag_index={tag_index}
                    id={2}
                />
                <TimestampTags
                    tag={tag3}
                    set_tag={set_tag3}
                    show_other={show_other3}
                    set_show_other={set_show_other3}
                    tag_other={tag_other3}
                    set_tag_other={set_tag_other3}
                    tag_index={tag_index}
                    id={3}
                />
                <TimestampTags
                    tag={tag4}
                    set_tag={set_tag4}
                    show_other={show_other4}
                    set_show_other={set_show_other4}
                    tag_other={tag_other4}
                    set_tag_other={set_tag_other4}
                    tag_index={tag_index}
                    id={4}
                />
                <TimestampTags
                    tag={tag5}
                    set_tag={set_tag5}
                    show_other={show_other5}
                    set_show_other={set_show_other5}
                    tag_other={tag_other5}
                    set_tag_other={set_tag_other5}
                    tag_index={tag_index}
                    id={5}
                />

                <div className="flex items-center content-center">
                
                {tag_index <= 5 &&
                <div className="flex-1">
                    <button
                        onClick={()=> {
                            
                            set_tag_index(prev=> {return prev+1})
                        }}
                    >
                        + Add a tag
                    </button>
                </div>
                }

                {tag_index >= 1 &&
                <div className="flex-0">
                    <button
                        onClick={()=> {
                            
                            set_tag_index(prev=> {return prev-1})
                        }}
                    >
                        Remove tag
                    </button>
                </div>
                }
                </div>


               
               
            </div>
        </div>

        
        <div className=" mt-5 flex items-center content-center">
            <div className="flex-1">
                <button
                    onClick={(e)=> {
                        e.preventDefault();
                        var msgstr = `/t ${clock}${strTag(tag1,tag_other1)}${strTag(tag2,tag_other2)}${strTag(tag3,tag_other3)}${strTag(tag4,tag_other4)}${strTag(tag5,tag_other5)}${strPrep(notes)}`
                        if (live_chat) {
                           sendmsg(e,channel_id,"message",{text: msgstr,bol_private:0,emoji:0},'')
                        } else {
                            saveTimestamp(msgstr)
                        }
                        closeModal();
                        }
                    }
                    className=" rounded-md font-bold hover:opacity-80 bg-blue-600 text-white px-4 py-2 sm:text-2xl"
                >
                    Save Timestamp
                </button>
            </div>
            <div>
            <button
                    onClick={async ()=> {
                        let confirmed = true;
                        if (notes.length > 0) {
                            confirmed = await isConfirmed({
                                        title: `Are you sure you want to close?`,
                                        body: <div className="text-left">You have unsaved changes you will lose.</div>,
                                        confirm:`Yes, close anyway`,
                                        cancel:`No, keep editing`,
                                        show_title: true,
                                        show_cancel: true,
                                        show_confirm: true
                                      });
                        } 
                        if (confirmed) closeModal();
                        
                    }}
                    className=" rounded-md hover:opacity-80 bg-gray-600 text-white px-4 py-2 sm:text-2xl"
                >
                    Cancel
                </button>
            
            </div>
        </div>
        </>)
}