import { allowedObject, config, postobject } from '@/lib/config';
import { formatBytes } from '@/lib/utils/formatBytes';
import { formatPlural } from '@/lib/utils/formatPlural';
import { getFileThumbnail, getFileType } from '@/lib/utils/getFileThumbnail';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import toast from 'react-simple-toasts';
import SvgJsx from '../svg/svg-jsx';
import ReactTooltip from 'react-tooltip';
import { getPercentage } from '@/lib/utils/getPercentage';
import LoaderToggle from '../loading/loader-toggle';
import ReactPlayer from 'react-player';
import { hasData } from '@/lib/utils/hasData';
import Image from 'next/legacy/image'
import { getAttrFromJson } from '@/lib/utils/getAttrFromJson';

export default function DropzoneWrapper({
  allowed_files,
  max_size,
  max_files,
  confirm_upload,
  file_type,
  onUploadSingle,
  api_url,
  files,
  set_files,
  total_files,
  set_total_files,
  track_files,
  set_track_files,
  changes,
  set_changes,
  processing,
  set_processing,
  swap_preview,
  preview_url,
  preview_json,
  preview_width,
  preview_height 

}) {

  const [isMounted,setIsMounted] = useState(false); // Need this for the react-tooltip


  useEffect(() => {
    setIsMounted(true);
    document.addEventListener('paste', onPaste);
    return () => {
    document.removeEventListener('paste', onPaste);
    };
  }, []);

  useEffect(()=> {
    if (files.length > 0) {
      set_total_files(prev=>{
        if (prev <= files.length) {
          return files.length
        } else {
          return prev
        }
      });
      set_changes(true);

       //upload immediately
      if (!confirm_upload) handleFile();
    }
    
  },[files,confirm_upload])



  const onDrop = useCallback(async (acceptedFiles) => {
    //console.log(acceptedFiles);

      //add to a preview array
      await set_files(prev=> 
          acceptedFiles.filter(f=>((allowed_files.indexOf(getFileType(f.name)) > -1 && f.size <= max_size))).map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
          .concat(prev)
        );

        //upload immediately
      // if (!confirm_upload) {
      //   await handleFile() 
      // }

  }, []);


  const onPaste = useCallback(async (event) => {

    //console.log("paste initiated")

    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const blob = item.getAsFile();
        console.log('Pasted file:', blob);
        if (allowed_files.indexOf(getFileType(blob.name)) > -1)  {
          if (blob.size <= max_size) {

            //add to a preview array
            await set_files(prev=>{ 
                  console.log("prev files",prev)
                  return [Object.assign(blob, {
                    preview: URL.createObjectURL(blob)
                  })]
                  .concat(prev);
                }
            )

          } else {
            toast(`This file is ${formatBytes(blob.size,0)} which is bigger than our maximum allowed size ${formatBytes(max_size,0)}.`, { time: 3000, className: '', clickable: true, clickClosable: false });
          }
          
        } else {
          toast(`File type ${getFileType(blob.name)} is not supported!`, { time: 3000, className: '', clickable: true, clickClosable: false });
        }
      }
    }
  }, []);


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles, 
    fileRejections
  } = useDropzone({
    onDrop,
    accept: allowedObject(allowed_files),
    minSize:0,
    maxSize:max_size,
    maxFiles:max_files,
    autoFocus: true
  });

  useEffect(()=>{
    if (fileRejections.length > 0 && fileRejections[0].file.size > max_size) { 
      toast(`This file is ${formatBytes(fileRejections[0].file.size,0)} which is bigger than our maximum allowed size ${formatBytes(max_size,0)}.`, { time: 3000, className: '', clickable: true, clickClosable: false });
    }
  },[fileRejections])
  
  // useEffect(() => {
  //   files.map(file=> console.log("file console",file))
  //   //return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  // },[files]);

  const handlePaste = (event) => {

    //console.log("paste initiated")

    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const blob = item.getAsFile();
        //console.log('Pasted file:', blob);
        // Check file type and size for allowed
          if (allowed_files.indexOf(getFileType(blob.name)) > -1 && blob.size <= max_size) handleUpload(blob,i,items.length);
      }
    }
  };

  const handleFile = async () => {

    set_processing(true)
    let tempFiles = [...files] // move this in so we don't impact the files array while looping through and removing items...
    await set_total_files(files.length) //used for tracking progress percentage
    for (let i = 0; i < tempFiles.length; i++) {
      if (allowed_files.indexOf(getFileType(tempFiles[i].name)) > -1 && tempFiles[i].size <= max_size) {
        await handleUpload(tempFiles[i],i,tempFiles.length);
        // await set_progress((tempFiles.length/(i+1))*100)
        //await URL.revokeObjectURL(tempFiles[i].preview)
        // await set_files(prev=>{
        //   let obj = [...prev];
        //   return obj.splice(i,1)
        // })
      }
    }
  };

  const handleUpload = (item,index,total_task) => {
    if (allowed_files.indexOf(getFileType(item.name)) == -1) {
      toast(`Cannot upload an unsupported file type!`, { time: 3000, className: '', clickable: true, clickClosable: false });
      return false
    }
    const url = api_url;
    const formData = new FormData();
    formData.append("file", item);
    formData.append("file_type", file_type);
    formData.append("upload_preset", "dryqolej");
    fetch(url, {
      method: "POST",
      body: formData
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        //toast(`Upload successful!`, { time: 3000, className: '', clickable: true, clickClosable: false });
         await onUploadSingle(data,index,item);
         await console.log("Upload successful!");
        
      })
      // .then(async (response) => {
      //   await set_files(prev=>{
      //     let obj = [...prev];
      //     return obj.splice(index,1)
      //   })
      // })
      // .then(async (response) => {
      //   await URL.revokeObjectURL(item.preview)
      //   //await console.log("all done and destroyed")
      // })
      // .then(async (response) => {
      //   await set_track_files(prev=>{
          
      //       // setTimeout(()=>resetProgress(),500)
          
      //     return prev+1
      //   })
      // })
      ;
  };

  const removeFile = (index,files,set_files) => { 
    var newarray = [...files];
    newarray.splice(index, 1)
    URL.revokeObjectURL(files[index].preview)
    set_files(newarray)
  }



  // console.log("fileRejections",fileRejections)

  if (swap_preview && hasData(preview_url)) {
    return (<>
     <DropzonePreview 
        preview_url={preview_url}
        preview_json={preview_json}
        allowed_files={allowed_files}
        preview_width={preview_width}
        preview_height={preview_height}
      />  
    </>)
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="p-2 relative h-[150px] w-full">
        <div className="absolute  h-[150px] left-0 right-0">
          <div className="rounded-md w-full overflow-hidden">
            <div className="rounded-md animate-pulse bg-gray-600 hover:bg-gray-500 overflow-hidden h-[150px] w-full transition-all ease-out duration-1000"
            ></div>
          </div>
        </div>
        {total_files > 0 && 
        <div className="absolute  h-[150px] left-0 right-0">
          <div className="rounded-md w-full overflow-hidden">
            <div className="rounded-md animate-pulse bg-blue-600 overflow-hidden h-[150px] transition-all ease-out duration-1000"
              style={{
                width: getPercentage(track_files,total_files)
              }}
            ></div>
          </div>
        </div>
        }
        <div {...getRootProps()} className="absolute h-[150px] left-0 right-0">
          <input {...getInputProps()} />
          
            
          <div className={`opacity-70 hover:opacity-100
            ${isDragActive && !isDragReject ? "animate-pulse border-4" : "border-2"}
            ${isDragReject ? "border-red-500" : "border-blue-500"}
            text-xs 2xs:text-sm xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-center rounded-md 
           flex items-center content-center justify-center  p-5 cursor-pointer h-[150px] w-full  border-dashed  outline-none`}>
            {processing && <>
              <LoaderToggle
                loadingstate={true}
                loadingClass='animate-pulse text-gray-400 w-5 h-5'
                toggle={{
                    bgColor: 'transparent',
                    centerColor: 'transparent',
                    holeColor: 'transparent',
                    pointerColor: '#ffffff',
                    dialerColor: '#ffffff77',
                }}
              /></>}
            {!processing && !isDragActive && <div className="flex items-center content-center w-full justify-center">
                                <div className="w-10 h-10 2xs:w-12 2xs:h-12 xs:w-14 xs:h-14 sm:w-16 sm:h-16  md:w-18 md:h-18 lg:w-20 lg:h-20 mr-3 sm:mr-5 relative">
                                  {/* <div className="w-10 h-10 sm:w-20 sm:h-20 absolute border rounded-full ">
                                    <SvgJsx 
                                      type='fill' 
                                      icon={postobject[file_type]?.icon} 
                                      className={` w-10 h-10 sm:w-20 sm:h-20 p-2 
                                                      `} 
                                      title='Upload'
                                      />  
                                  </div> */}
                                  <div className="w-10 h-10 2xs:w-12 2xs:h-12 xs:w-14 xs:h-14 sm:w-16 sm:h-16  md:w-18 md:h-18 lg:w-20 lg:h-20 absolute border rounded-full bg-gray-700">
                                    <SvgJsx 
                                      type='fill' 
                                      icon={postobject[file_type]?.icon} 
                                      className={` w-10 h-10 2xs:w-12 2xs:h-12 xs:w-14 xs:h-14 sm:w-16 sm:h-16  md:w-18 md:h-18 lg:w-20 lg:h-20 p-2 
                                                      `} 
                                      title='Upload'
                                      />  
                                  </div>
                                </div>
                                <div className="flex-0 ">
                                  <div>Drag &amp; drop {max_files > 1 ? "" : "your "}{postobject[file_type]?.type} file{max_files > 1 ? "s" : ""} here,</div>
                                  <div>paste from your clipboard,</div>
                                  <div>or click here to select {max_files > 1 ? "" : "your "}file{max_files > 1 ? "s" : ""}</div>
                                </div>
                              </div>}
            {!processing && isDragActive && !isDragReject && "Drop your file here"}
            {!processing && isDragReject && ({
                            "too-many-files":`You are dragging too many files. The limit is ${max_files}.`,
                            "file-invalid-type":`You are dragging in an unsupported file type.`
                            }[fileRejections[0]?.errors[0]?.code] 
                            || fileRejections[0]?.errors[0]?.code)}
          </div>
         
        </div>

        
      </div>

     
      
      {confirm_upload && 
      <>
          <div
            className="flex flex-col items-center content-center justify-center mt-[20px] border border-gray-700 rounded-md gap-y-1 p-1 max-h-[400px] overflow-auto"
          ><ThumbList 
            files={files}
            set_files={set_files}
            removeFile={removeFile}
            allowed_files={allowed_files}
            processing={processing}
            isMounted={isMounted}
          /></div>

          {files.length > 0 && (
          <div className="flex items-center content-center px-2">
            <div className="flex-1">
              {/* <button 
                className="mx-auto font-bold text-xl  rounded-md mt-5 bg-blue-600 px-4 py-2 text-white text-center cursor-pointer hover:bg-blue-500" 
                onClick={handleFile}
              >
                Upload {files.length} {formatPlural("File",files.length)}
              </button> */}
              <button 
                className={`mx-auto font-bold text-xl  rounded-md mt-5 px-4 py-2 text-white text-center 
                ${processing 
                  ? "bg-gray-600 cursor-no-drop"
                  : "bg-blue-600 hover:bg-blue-500  cursor-pointer"}
                `} 
                onClick={handleFile}
                disabled={processing}
              >
                <LoaderToggle
                  loadingstate={processing}
                  actionText={`Upload ${files.length} ${formatPlural("File",files.length)}`}
                  actionTextShort={`Upload`}
                  loadingClass='animate-pulse text-gray-400 w-5 h-5'
                  loadingText={`Uploading ${total_files} ${formatPlural("File",total_files)}`}
                  toggle={{
                      bgColor: 'transparent',
                      centerColor: 'transparent',
                      holeColor: 'transparent',
                      pointerColor: '#ffffff',
                      dialerColor: '#ffffff77',
                  }}
                />
              </button>
            </div>
            <div>
              <button 
                className="mx-auto border rounded-md mt-5 border-gray-700 px-4 py-2 text-white text-center cursor-pointer hover:border-white" 
                onClick={()=>{ 
                  files.forEach(file => URL.revokeObjectURL(file.preview));
                  set_files([]);
                }}
              >
                Remove all
              </button>
            </div>
          </div>
          )}

        {files.length == 0 && (
          <div className="flex items-center content-center px-2">
            <div className="flex-0">
              <div 
               {...getRootProps()}
                className="mx-auto font-bold text-xl  rounded-md mt-5 bg-gray-600 text-gray-400 px-4 py-2  text-center cursor-pointer" 
              
                //onClick={()=>alert('Select files before trying to start your upload')}
              >
                Upload {files.length} {formatPlural("File",files.length)}
              </div>
            </div>
            
          </div>
          )}

      </>}


    </div>
  );
}

function ThumbList({files,set_files,removeFile,allowed_files,processing,isMounted}) {

  return (<>
          {files.length == 0 && 
          <div className="items-center content-center justify-center py-5 italic text-gray-400">
            <div>
            No files selected
            </div>
          </div>
          }
          {files.length > 0 && 
          <div className="pt-0 w-full">
          {files.map((file,index) => (
            <div key={`${file.name}-${index}`} className="w-full px-2 ">
              <div className={`flex-1 w-full flex items-center content-center gap-x-2 rounded-md
              
              ${allowed_files.indexOf(getFileType(file.name)) == -1 ? "bg-red-500" : "hover:bg-gray-700"}
              `}
                
              >
                <div className="">
                  <img
                    src={getFileThumbnail(file)}
                    //onLoad={() => { URL.revokeObjectURL(file.preview) }} 
                    alt=""
                    className="w-[50px] border"
                    data-tip
                    data-for={`${file.name}-${index}`}
                  />
                   {(isMounted && getFileType(file.name) == "image") && <ReactTooltip
                    id={`${file.name}-${index}`}
                    place='right' 
                    effect='solid' 
                    clickable={false}
                    delayHide={0}
                    delayUpdate={0}
                    className="reacttooltip rounded-3xl shadow-lg"
                  >
                      <div className="w-64 helptips text-center">
                        <div className="">
                        <img
                          src={getFileThumbnail(file)}
                          //onLoad={() => { URL.revokeObjectURL(file.preview) }} 
                          alt=""
                          className="w-[400px] border"
                          data-tip
                          data-for={`${file.name}-${index}`}
                        />
                        </div>
                        <div className="mt-2 font-bold">
                          {file.name}
                        </div>
                        <div>
                        {formatBytes(file.size,0)}
                        </div>
                      </div>
                  </ReactTooltip>}
                </div>
                <div className="text-xs flex-1">
                  <div
                    className="font-bold"
                  >
                    {file.name}
                  </div>
                  <div>
                    {formatBytes(file.size,0)}
                  </div>
                </div>
                
                {!processing &&
                <div>
                <button
                  className="cursor-pointer px-2"
                  onClick={()=>{ if (confirm("Are you sure you want to remove this file from your upload list?")) removeFile(index,files,set_files)}}
                >
                   <SvgJsx 
                        type={'fill'}
                        icon={"trash-sm"}
                        className={`h-5 w-5  `}
                        title={"remove"}
                    />
                </button>
                </div>
                }
              </div>
            </div>
          ))}
          </div>
          }
        </>)
}


export function DropzonePreview({
  preview_url,
  preview_json,
  allowed_files,
  preview_width,
  preview_height
}) {

  const [file_loaded, set_file_loaded] = useState(true)
  const [file_loaded_attempts, set_file_loaded_attempts] = useState(0)
  const [thumb_loaded, set_thumb_loaded] = useState(false)
  const [thumb_loaded_attempts, set_thumb_loaded_attempts] = useState(0)
  const [preview_url_active,set_preview_url_active] = useState(preview_url)

  useEffect(()=>{
    setTimeout(()=>set_preview_url_active(`${preview_url}?cache=${+new Date()}`),2000)
  },[preview_url])
  
    return (<>
              
      {allowed_files.indexOf("image") > -1 &&
      <div className="max-w-[400px]"
          style={ { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url("/images/app/live-64x64.gif")`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
            backgroundPosition: `center`
          
          } }
      >
        <Image
          onLoad={() => set_thumb_loaded(true)}
          loader={()=>preview_url_active}
          src={preview_url_active}
          width={preview_width}
          height={preview_height}
          alt="Image"
          layout={"responsive"}
          quality={100}
           placeholder="empty"
           unoptimized={true}
          blurDataURL={config.avatar.blurdataurl}
        /> 

      </div>
      }
    
      {allowed_files.indexOf("audio") > -1 &&
      <>
                {(!file_loaded) &&
                
                <div className="text-center flex items-center content-center justify-center my-2">
                  <div
                    onClick={()=>set_file_loaded(true)}
                    className="px-4 py-2 text-lg bg-gray-500 rounded-md "
                  >
                    Load audio
                  </div>
                </div>
                }
                {file_loaded &&
                 ReactPlayer.canPlay(preview_url) &&
                 <> 
                 <ReactPlayer 
                    
                    url={preview_url} 
                    width='100%'
                    height='50px'
                    controls={true}
                    onReady={()=>{
                      //console.log('loaded')
                      set_file_loaded(true)
                    }}
                    onError={(e)=>{
                      console.log("error",e)
                      set_file_loaded(false)
                      if (file_loaded_attempts <= 5) {
                        setTimeout(()=>{
                          set_file_loaded(true)
                          set_file_loaded_attempts(prev=>prev+1)
                        },1000);
                      }
                    }}
                  
                  />
                </>
                }

    </>
    }
                
              </>)
  

}