import { hasData } from "./hasData";

export function slugify(id,words) {

    var vid = id;
    //var vwords = hasData(words) ? words?.replace(/\W+/g,"-") : `untitled`;
    var vwords = hasData(words) ? words?.replace(/[^a-zA-Z0-9-]/g,"-") : `untitled`;
    vwords = vwords?.replace(/--+/g,"-")
    return `${vid}-${vwords?.toLowerCase()}`

}

export function sluginput(id,text,placeholder) {
    return slugify(id,hasData(text) ? text : placeholder).replace(`${id}-`,'')
  }