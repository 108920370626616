
import { config, description_format, meta, title_format } from '@/lib/config'
import Head from 'next/head'
import { useEffect, useState } from 'react'

export default function LayoutPageBlank({ 
  ssr_data_tribe
  ,title = meta(ssr_data_tribe).title
  ,url = meta(ssr_data_tribe).url
  ,image = meta(ssr_data_tribe).image
  ,description = meta(ssr_data_tribe).description
  ,keywords = meta(ssr_data_tribe).keywords
  ,children 
}) {

  // const [ IsMounted, set_isMounted ] = useState<boolean>()
  // useEffect(() => {
  //   set_isMounted(true)
  // }, [])

  const var_description = description_format(description)
  const var_title = title_format(title,ssr_data_tribe)

  return (
    <div className="bg-gray-800">
        <Head>
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color={meta(ssr_data_tribe).color} />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="icon" href="/favicon.ico" />
              <link rel="manifest" href="/site.webmanifest" />
              <link rel="sitemap" type="application/xml" title="Sitemap" href={`${ssr_data_tribe?.server_website}/sitemap.xml`} />
              <meta name="apple-mobile-web-app-title" content={ssr_data_tribe?.brand_name} />
              <meta name="application-name" content={ssr_data_tribe?.brand_name} />
              <meta name="theme-color" content={meta(ssr_data_tribe).color}></meta>
              <meta name="msapplication-TileColor" content={meta(ssr_data_tribe).color} />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content={meta(ssr_data_tribe).color} />
    
              <meta name="robots" content="index, follow" />
              
              <meta property="twitter:card" content="summary" />
              <meta property="og:site_name" content={ssr_data_tribe?.brand_name} />
              <meta property="og:type" content={meta(ssr_data_tribe).type} />
              
          {/* CUSTOM OVERRIDES */}
          <link 
            rel="canonical" 
            href={url} 
            key="canonical"
          />
          <title
            key="title"
          >
            {var_title}
          </title>
          <meta 
            name="description"
            content={var_description} 
            key="description"
          />
          <meta 
            name="keywords"
            content={keywords} 
            key="keywords"
          />
          <meta 
            property="og:title" 
            content={var_title} 
            key="metaogtitle"
          />
          <meta 
            name="og:description" 
            content={var_description} 
            key="ogdescription"
          />
          <meta 
            property="og:url" 
            content={url} 
            key="ogurl"
          />
          <meta
            property="og:image"
            content={`${image}`}//?v=${Math.floor(Date.now() / 100)}`}
            key="ogimage"
          />
          <meta 
            name="twitter:image:alt" 
            content={var_title}
            key="twitterimagealt"
          />

          <meta 
            property="twitter:url" 
            content={url} 
            key="twitterurl"
          />
          <meta 
            property="twitter:title" 
            content={var_title}
            key="twittertitle"
          />
          <meta 
            property="twitter:description" 
            content={var_description} 
            key="twitterdescription"
          />
          <meta
            property="twitter:image:src"
            content={`${image}`}
            key="twitterimagesrc"
          />
             
        </Head>
        {children}
        
    </div>
  )
}