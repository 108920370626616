export default function WizardHeader({
    wizard_array
   ,wizard_id
   ,set_wizard_id
   ,disable_mobileview=false
  }) {
   return (<>

   {/* DESKTOP */}
   <div className={`${disable_mobileview ? 'flex' : 'hidden sm:flex'} items-center content-center border-b pb-4 border-gray-700`}>
     {wizard_array.map((e,index)=>
       <div
         key={index}
         className={`px-3 py-1 rounded-md cursor-pointer mr-2
         ${e.admin == 1 ? 'text-red-600' : ''}
         ${index==wizard_id ? 'bg-gray-700' : ''} hover:bg-gray-800`}
         onClick={()=> set_wizard_id(index)}
       >
         {e.title}
       </div>
     )}
     </div>

    {/* MOBILE */}
    {!disable_mobileview &&
     <div className='sm:hidden block border-b pb-4  border-gray-700'>
        <select
          onChange={(e)=> set_wizard_id(e.target.value)}
          value={wizard_id}
          className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
          
          text-white placeholder-gray-500 bg-gray-800
          `}
        >
     {wizard_array.map((e,index)=>
       <option
         key={index}
         value={index}
       >
         {index+1}. {e.title}
       </option>
     )}
     </select>
     </div>
     }
   
   </>)
  }