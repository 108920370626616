export default function ToggleButton({
     bol_item = "1"
    ,toggle_text = "Include text"
    ,clickEvent=()=>{}
    ,focusEvent=()=>{}
    ,changeEvent=()=>{}
    ,off_icon = (<>
                    <svg className="bg-black h-5 w-5 text-gray-400 rounded-full" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </>)
    ,on_icon = (<>
                    <svg className="bg-black h-5 w-5 text-brand rounded-full" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </>)
    ,size='medium'
}) {

  //define toggle size options
  //medium
  var toggle_height = 'h-8'
  var toggle_width = 'w-16'
  var toggle_inner_height = 'h-7'
  var toggle_inner_width = 'w-7'
  var translate_on = 'translate-x-8'

  if (size == 'small') {  //small
     toggle_height = 'h-6'
     toggle_width = 'w-12'
     toggle_inner_height = 'h-5'
     toggle_inner_width = 'w-5'
     translate_on = 'translate-x-6'
 } else if (size == 'long') { //long
    toggle_height = 'h-8'
    toggle_width = 'w-24'
    toggle_inner_height = 'h-7'
    toggle_inner_width = 'w-7'
    translate_on = 'translate-x-16'
  } else if (size == 'big') { //big
    toggle_height = 'h-10'
    toggle_width = 'w-20'
    toggle_inner_height = 'h-9'
    toggle_inner_width = 'w-9'
    translate_on = 'translate-x-9'
 }
 


    return (<>
            <button 
              type="button" 
              tabIndex={0}
              aria-pressed="false" 
              aria-labelledby="toggleLabel"
              onClick={clickEvent}              
              className={`
                      inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none
                        ${toggle_height} ${toggle_width} shadow-inner
                        ${bol_item=="1" 
                        ? ` bg-brand-darkest hover:bg-brand ` 
                        : ` bg-gray-400 hover:bg-gray-500  `} 
                         `}
            >
              {size=='long' &&
              <span className={`absolute h-full  font-thin text-xs flex
                              ${bol_item=="1" 
                                ? " text-white ml-1.5" 
                                : " text-gray-400 ml-8"}
                              `}>
                <span className="my-auto flex-1 flex">
                  Upgrade
                </span>
              </span>
              }
              <span className="sr-only">
                {toggle_text}
              </span>
          
              <span className={`pointer-events-none ${bol_item=="1" ? ` ${translate_on} ` : " translate-x-0 "}  relative inline-block ${toggle_inner_height} ${toggle_inner_width} rounded-full 
              bg-black shadow  ring-0 transition ease-in-out duration-200`}>
                  
                  <span className={` ${bol_item=="1" ? " opacity-0 ease-out duration-100 " : " opacity-100 ease-in duration-100 "}  z-50 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`} aria-hidden="true">
                      {off_icon}
                  </span>
                  
                  <span className={`${bol_item=="1" ? " opacity-100 ease-in duration-100 " : " opacity-0 ease-out duration-100 "} z-50 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`} aria-hidden="true">
                      {on_icon}
                  </span>
              </span>
            </button>
        </>)
    
}