import { config } from "../config";

export function getFileType(n) {
    var arrn = n.split(".");
    var extention = `.${arrn[arrn.length-1]?.toLowerCase()}`
    if (config.upload.mime_types.image.indexOf(extention) > -1) return "image"
    if (config.upload.mime_types.audio.indexOf(extention) > -1) return "audio"
    if (config.upload.mime_types.video.indexOf(extention) > -1) return "video"
    return "unknown"
}
  
  export function getFileThumbnail(file) { 
    return {image: file.preview,
              audio: config.upload.audio_thumb,
              video: config.upload.video_thumb
            }[getFileType(file.name)] || config.upload.unknown_thumb
  }