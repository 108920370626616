
import * as React from "react";
import { useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import WizardHeader from "@/components/templateux/wizard/wizard-header";
import isAdminUser from "@/lib/utils/isAdminUser";
import WizardSectionForm from "@/components/templateux/wizard/wizard-section-form";
import WizardOwner from "@/components/templateux/wizard/wizard-owner";
import { sluginput } from "@/lib/utils/slugify";
import WizardPager from "@/components/templateux/wizard/wizard-pager";
import { truncate } from "@/lib/utils/truncate";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { ifNull } from "@/lib/utils/ifNull";
import { tablehelptype } from "@/lib/config";
import DropzoneWrapper from "@/components/templateux/upload/dropzone-wrapper";
import { getAttrFromJson } from "@/lib/utils/getAttrFromJson";
import { validJson } from "@/lib/utils/isJsonValid";

//TODO: USE THIS FORMAT...`https://${process.env.NEXT_PUBLIC_SERVER_ENCODER}/#targetUrl=targets/${process.env.NEXT_PUBLIC_ENCODER_ID}?${(`key=${ssr_data_settings?.id}_${current_stream_api_key}${params}&sourceUrl=${cron_url}`)}`

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //soundboard info
  prerecording_id?: any;
  itemdata?: any;
  parentdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  prerecordingcache?:any;
  set_prerecordingcache?:any;
  typevalue?:any;
  set_typevalue?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;
  
}

function PrerecordingForm({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,processing
  ,set_processing
  ,set_changes
  ,changes
  ,max_files

  //prerecording info
  ,prerecording_id
  ,itemdata
  ,parentdata
  ,itemdataisLoading
  ,itemdataisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,prerecordingcache
  ,set_prerecordingcache
  
  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype

}: Props) {


  const [ispageloaded,set_ispageloaded] = useState(false)
  useEffect(()=>{set_ispageloaded(true)},[])
 
  const [admin_user_name,set_admin_user_name] = useState(!itemdataisLoading 
                                                          && !itemdataisError 
                                                          && itemdata 
                                                          && ifNull(itemdata?.user_name,ownerdata?.user_name))
  const [bol_admin_user_name,set_bol_admin_user_name] = useState("0")
  const [user_id,set_user_id] = useState(userdata?.id)
  
  const [_prerecording_title, set_prerecording_title] = useState(ifNull(itemdata?.prerecording_title,''))
  const [_prerecording_description, set_prerecording_description] = useState(ifNull(itemdata?.prerecording_description,''))
  const [_prerecording_tags, set_prerecording_tags] = useState(ifNull(itemdata?.prerecording_tags,''))
  const [_bol_public, set_bol_public] = useState(ifNull(itemdata?.bol_public,'1'))

  const [_access_level, set_access_level] = useState(ifNull(itemdata?.access_level,'1'))
  const [_slug, set_slug] = useState(ifNull(itemdata?.slug,''))
 
  const [_file_json, set_file_json] = useState(ifNull(itemdata?.file_json,'[]'))

  const [files, set_files] = useState([]);
  const [total_files,set_total_files] = useState(0)
  const [track_files,set_track_files] = useState(0)
  
  useEffect(()=>{


    if (form_reset==1 
      && ((
          hasData(prerecording_id) 
          && prerecording_id.toString() !== "0" 
          && !itemdataisLoading && !itemdataisError
          )
        )
      ) {

      set_admin_user_name(hasData(itemdata?.user_name) 
                          ? itemdata?.user_name 
                          : userdata?.user_name)


        set_prerecording_title(hasData(itemdata?.prerecording_title)
                                    ? itemdata?.prerecording_title 
                                    : ''
                                  )
        set_prerecording_description(hasData(itemdata?.prerecording_description) 
                                    ? itemdata?.prerecording_description 
                                    : ''
                                    )
        set_prerecording_tags(hasData(itemdata?.prerecording_tags)
                                    ? itemdata?.prerecording_tags 
                                    : ''
                                  )

      set_bol_public(hasData(itemdata?.bol_public) 
                                  ? itemdata?.bol_public 
                                  : '1'
                                  )
     

        set_access_level(hasData(itemdata?.access_level) 
                                  ? itemdata?.access_level 
                                  : '1')

        set_slug(hasData(itemdata?.slug) 
                                  ? itemdata?.slug 
                                  : '')

        set_form_reset(0) //make sure we dont remove the data again now that it's filled in.
    
    }
 
},[
   itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,prerecording_id
  ,changes
  ,form_reset
  ,set_form_reset
  ,userdata
])




  const { isConfirmed } = useConfirm();


  function slugformat(value) {
    if (value?.length > 0) {
      set_slug(truncate(sluginput(prerecording_id,value,value).replace(`${prerecording_id}-`,''),25,'')?.replace(`Untitled`,'')?.toLowerCase())
    } else {
      set_slug('')
    }
  }

  const [wizard_id,set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title:'info'
      ,description:''
      ,short: 'info'
      ,fields: [
                {
                  type:'text'
                  ,title:'Title'
                  ,description:''
                  ,helptip:''
                  ,name:'prerecording_title'
                  ,placeholder:''
                  ,value:_prerecording_title
                  ,set_value:set_prerecording_title
                  ,meta:{maxChars:65}
                },
                
                
                 {
                    type: 'textarea-autosize'
                    ,title:'Description'
                    ,description:''
                    ,helptip:''
                    ,name:'prerecording_description'
                    ,placeholder:''
                    ,value:_prerecording_description
                    ,set_value:set_prerecording_description
                    ,meta:{minRows:2,maxRows:10}
                  },
                {
                  type:'text'
                  ,title:'Tags'
                  ,description:'separate tags with commas'
                  ,helptip:''
                  ,name:'prerecording_tags'
                  ,placeholder:''
                  ,value:_prerecording_tags
                  ,set_value:set_prerecording_tags
                }
      ]
    },
   {
    title:'advanced'
    ,description:''
    ,short: 'advanced'
    ,fields: [


        // {
        //   type:'select'
        //   ,title:'Who can use this soundboard?'
        //   ,description:''
        //   ,helptip:''
        //   ,name:'access_level'
        //   ,placeholder:''
        //   ,options:[
        //     {
        //       text:'Everyone'
        //       ,value: '0'
        //     },
        //     {
        //       text:'Registered Members'
        //       ,value: '1'
        //     },
        //     {
        //       text:'Contributors Only'
        //       ,value: '2'
        //     },
        //   ]
        //   ,value:_access_level
        //   ,set_value:set_access_level
        // },
        // {
        //   type:'text'
        //   ,title:'Customize your URL Ending'
        //   ,description:<div className='text-xs'>{ssr_data_tribe?.server_website}/{admin_user_name}/soundboard/{(soundboard_id?.toString()=="0" ? "42069" : soundboard_id?.toString())}-<span className='text-blue-400'>{sluginput((soundboard_id==0 ? 42069 : soundboard_id),_slug,_soundboard_title)}</span></div>
        //   ,helptip:''
        //   ,name:'slug'
        //   ,placeholder:`${sluginput(soundboard_id,_slug,_soundboard_title).replace(`${soundboard_id}-`,'')}`
        //   ,value:_slug
        //   ,set_value:slugformat
        //   ,meta:{maxChars:25}
        // },
        
        {
          type:'selectoptions'
          ,title:'Who can listen to this recording?'
          ,description:''
          ,helptip:''
          ,name:'bol_public'
          ,placeholder:''
          ,value:_bol_public
          ,set_value:set_bol_public
          ,options:[
            {
              text:'Private (only me)'
              ,value: '0'
            },
            {
              text:'Public (everyone)'
              ,value: '1'
            },
          ]
        }
        
      ]
    }
  ]



          



    const [isAdmin,set_isAdmin] = useState(isAdminUser(
       ownerdata?.id?.toString()          //user_id
      ,ownerdata?.bol_staff_help    //bol_staff_help
      ,ownerdata?.staff_json        //staff_json
      ,ownerdata?.bol_admin_help    //bol_admin_help
      ,userdata?.id?.toString()                            //my_id
      ,userdata?.bol_admin?.toString()              //global_admin
      ,tablehelptype('prerecording')
  ))
   




  function handleSubmit(e) {

    e.preventDefault();


      const prerecording_data = {
          prerecording_id: prerecording_id
        , prerecording_title: _prerecording_title
        , prerecording_description: _prerecording_description
        , prerecording_tags: _prerecording_tags
        , bol_public: _bol_public
        , access_level: _access_level
        , slug: hasData(_slug) ? _slug : `${sluginput(prerecording_id,_slug,_prerecording_title).replace(`${prerecording_id}-`,'')}`
        //, soundboard_json: _soundboard_json 
        , file_json: _file_json 
        , admin_help_user_id: user_id
        , set_submitting
      } 



      submitHandler(prerecording_data
      ,`/api/private/prerecording/update`
      ,'POST'
      ,{
        'Content-Type': 'application/json',
      }
      ,'Your changes were saved'
    )
    set_changes(false);
    let cachedate = + new Date;
    set_prerecordingcache(cachedate?.toString())

    
  }
  

  return (
    <>
    {/* info COMPONENT */}
    <div className="w-full">

      

      <WizardHeader
        wizard_array={wizard_array}
        wizard_id={wizard_id}
        set_wizard_id={set_wizard_id}
        disable_mobileview={true}
      />

      {/* FORM */}
      <div>
      <form 
        className="space-y-6" 
        onSubmit={handleSubmit}
      >


        <WizardSectionForm
          section_id={0}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        ><>

        

        <div className="flex-1">
                                                
                                                
                <div className="flex items-center content-center">
                    <div className="flex-0">
                        <h3 className="font-bold">
                           Attach an mp3 file
                        </h3> 
                    </div>
                </div>
                

        </div>

        {getAttrFromJson(_file_json[0],`fieldFile`,`url`)}

                {hasData(validJson(_file_json,[])[0]?.url) &&
                <div className="text-right text-xs mb-4 -mt-5 mr-2 ">
                    <button onClick={()=> {
                        if (confirm("Are you sure you want to replace the original file?")) {
                            set_file_json('[]')
                        }
                    }}
                    className="border px-2 py-0.5 rounded-full border-gray-700 hover:border-white"
                    >
                    remove file
                    </button>
                </div>
                }
                
                <DropzoneWrapper 
                    allowed_files={["audio"]}
                    file_type={1} //sounds - see the map in lib/config/postobject
                    api_url={`/api/private/upload/multi?update=prerecording&type=audio&cid=${prerecording_id}`}
                    max_size={250000000} //250mb
                    max_files={1}
                    confirm_upload={false}
                    onUploadSingle={async (data)=> { 

                        set_file_json(prev=>{
                            let new_array = JSON.stringify([JSON.parse(data?.metadata_json)]);
                            set_changes(true);
                            set_processing(false);
                            return new_array;
                        })

                      
                    }}
                    files={files}
                    set_files={set_files}
                    total_files={total_files}
                    set_total_files={set_total_files}
                    track_files={track_files}
                    set_track_files={set_track_files}
                    changes={changes}
                    set_changes={set_changes}
                    processing={processing}
                    set_processing={set_processing}
                    swap_preview={true}
                    preview_url={validJson(_file_json,[])[0]?.url}
                    preview_json={validJson(_file_json,[])[0]}
                    preview_width={validJson(_file_json,[])[0]?.width}
                    preview_height={validJson(_file_json,[])[0]?.height}
                />
        
        </></WizardSectionForm> 

        <WizardSectionForm
          section_id={1}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
          ><>
          
          </></WizardSectionForm> 

        <WizardSectionForm
          section_id={2}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        >



                <WizardOwner
                  isAdmin={isAdmin}
                  bol_admin_user_name={bol_admin_user_name}
                  set_bol_admin_user_name={set_bol_admin_user_name}
                  admin_user_name={admin_user_name}
                  set_admin_user_name={set_admin_user_name}
                  user_id={user_id}
                  set_user_id={set_user_id}
                  submitting={submitting}
                  set_submitting={set_submitting}
                  processing={processing}
                  set_processing={set_processing}
                  changes={changes}
                  set_changes={set_changes}
                  title={`Set a Broadcast Owner`}
                  description={``}
                />


    </WizardSectionForm>  

    <WizardPager
      wizard_id={wizard_id}
      set_wizard_id={set_wizard_id}
      submitting={submitting}
      changes={changes}
      actionText={actionText}
      wizard_array={wizard_array}
    />
 
        
      </form>
    </div>
    {/* SET referral FORM */}

    </div>
    {/* // referral SET COMPONENT */}
  </>
  )
}

export default PrerecordingForm



