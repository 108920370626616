import FormInput from "../form-input/form-input";

export default function WizardOwner({
    isAdmin
    ,bol_admin_user_name
    ,set_bol_admin_user_name
    ,admin_user_name
    ,set_admin_user_name
    ,user_id
    ,set_user_id
    ,submitting
    ,set_submitting
    ,processing
    ,set_processing
    ,changes
    ,set_changes
    ,title='Post Author'
    ,description=''
}) {
    return (<>
            <div>
              <div className="mt-4 mb-2">
                <div>
                    <h3 className="font-bold ">
                    {title}
                    </h3>
                </div>
                <div className="italic">
                {description}
                </div>
              </div>

                <div 

                    className={`rounded-lg flex flex-item mx-1 overflow-hidden border 
                 
                          
                            bg-blue-900 border-blue-700
                            ${ isAdmin && bol_admin_user_name=="0"
                               ? 'cursor-pointer  hover:bg-blue-800'
                               : ''}
                    `}
                >
                  <div 
                    className="flex-1 flex items-center content-center"
                  >

             
              
             
              

            

                  
                    <div 
                        className={`flex-1  flex items-center content-center 
                                    text-white 
                                    
                                    `}
                    > 
                        
                      {/* STATIC */}
                      <div
                          className="flex-0 px-2 py-0.5 mx-2 w-16 text-center text-xs rounded-md text-white bg-blue-700 font-bold" 
                      >
                        owner
                      </div>

                      {/* STATIC */}
                      {bol_admin_user_name=="0" &&
                      <div 
                        className='flex-1 font-bold text-md  flex items-center content-center'
                        onClick={()=> {
                          if (isAdmin) set_bol_admin_user_name("1");
                        }}
                      >
                        <div className="flex-1 my-2">
                        {admin_user_name}
                        </div>
                        {isAdmin &&
                        <div className="flex-0">
                          <div 
                            onClick={()=>set_bol_admin_user_name("1")}
                            className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                          >
                          Edit
                          </div>
                        </div>
                        }
                      </div>

                      
                      }

                      {/* EDITING */}
                      {bol_admin_user_name=="1" &&
                      <div className='flex-1 font-bold text-xl flex items-center content-center'>
                        <div className="flex-1">
                        <FormInput type={'user'}
                          title={'Owner'}
                          description={''}
                          helptip={''}
                          
                          name={'admin_user_name'}
                          placeholder={"Username"}
                          
                          value={admin_user_name}
                          set_value={set_admin_user_name}
                          hidden_value={user_id}
                          set_hidden_value={set_user_id}
          
                          //submitting
                          submitting={submitting}
                          set_submitting={set_submitting}
                          processing={processing}
                          set_processing={set_processing}
                          set_changes={set_changes}
                          changes={changes}
                        /> 
                        </div>
                        <div className="flex-0 ">
                          <div 
                            onClick={()=>set_bol_admin_user_name("0")}
                            className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                          >
                            Set
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  

                  </div>
                </div>
            </div>
             </>)
}