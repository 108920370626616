

import AvatarFlair from "./avatar-flair"
import ProfileContributorTag from "@/components/profile/profile-contributor-tag"
import { chatauthor } from "@/components/ably/chatauthor"
import { config } from "@/lib/config"
import { truncate } from "@/lib/utils/truncate"
import { FlairPill } from "@/components/profile/chat/chat-flair"
import AvatarFlairClean from "./avatar-flair-clean"

function AuthorCredit({
     display_data
    ,isPlaying=false
    ,profiledata={
       bol_flair:0
      ,flair_json:[]
      ,id:0
      ,user_name:''
      ,bol_staff_help:0
      ,staff_json:[]
      ,bol_admin_help:0
      ,contributor_level:0
      ,bot_default_name: config.chat.bot.default_name
      ,bot_default_image: config.chat.bot.default_image
    }
    ,show_avatar=true
    ,show_user_name=true
    ,show_flair=true
    ,custom_flair=''
    ,bol_mod=0
    ,containerClassBig='w-8 h-7 '
    ,containerClassSmall='w-5 h-7 '
    ,bgClass='w-6 h-6 '
    ,avatarClass='w-6 h-6 mr-2 border-2 border-transparent '
    ,rotaryContainerClass='w-[28px] h-6 mr-3'
    ,rotaryInternalClass='w-8 h-8'
    ,sizes={
         width:25
        ,height:25
        ,quality:50
    }
    ,chatWidth=config.chat.windowoffset.minmessagewidth
}) {



    return (<>
                    <div className="flex-0 flex items-center content-center   h-8">
                      
                          {/* AVATAR + NAME CONTAINER */}
                          <div className={`flex-0 flex flex-none items-center content-center group`}>

                            {/* AVATAR CONTAINER */}
                            {show_avatar &&
                            <div className={`flex-0 mr-1 flex items-center content-center`}
                            >

                              {/* AVATAR FLAIR */}
                              <div className={`hidden  ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:block"}`}>
                                
                                <AvatarFlairClean 
                                  
                                  isPlaying={isPlaying}
                                  isContributor={display_data?.contributor_level > 0}
                                  isBot={["server","bot"].indexOf(display_data?.type?.toString()) > -1}
                                  avatar_url={chatauthor(display_data,`avatar_url`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  avatar_url_sfw={chatauthor(display_data,`avatar_url_sfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  bol_nsfw={chatauthor(display_data,`bol_nsfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  avatar_crdate={chatauthor(display_data,`avatar_crdate`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  id={chatauthor(display_data,`id`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  user_name={chatauthor(display_data,`user_name`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  
                                  containerClass={containerClassBig}
                                  sizes={sizes}
                                  bgClass={bgClass}
                                  avatarClass={avatarClass}
                                  rotaryContainerClass={rotaryContainerClass}
                                  rotaryInternalClass={rotaryInternalClass}
                                />
                              </div>
                              <div className={`block  ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:hidden"} mr-1`}
                              >
                                <AvatarFlairClean 
                                  
                                  isPlaying={isPlaying}
                                  isContributor={display_data?.contributor_level > 0}
                                  isBot={["server","bot"].indexOf(display_data?.type?.toString()) > -1}
                                  avatar_url={chatauthor(display_data,`avatar_url`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  avatar_url_sfw={chatauthor(display_data,`avatar_url_sfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  bol_nsfw={chatauthor(display_data,`bol_nsfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  avatar_crdate={chatauthor(display_data,`avatar_crdate`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  id={chatauthor(display_data,`id`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  user_name={chatauthor(display_data,`user_name`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                  containerClass={containerClassSmall}
                                  sizes={sizes}
                                  bgClass={bgClass}
                                  avatarClass={avatarClass}
                                  rotaryContainerClass={rotaryContainerClass}
                                  rotaryInternalClass={rotaryInternalClass}
                                />
                              </div>

                            </div>
                            }
                            
                            {/* USERNAME - ALL CONTAINER */}
                            {show_user_name &&
                            <div className="flex items-center content-center">

                              {/* USERNAME - TOP */}
                              <div className="flex items-center content-center">

                                 {/* USERNAME */}
                                <div className={`flex-0 font-bold ${display_data?.contributor_level > 0 ? "text-white" : "text-gray-200"} mr-1 text-sm  ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:text-base"}`}>
                                  {chatauthor(display_data,`user_name`,profiledata?.bot_default_name,profiledata?.bot_default_image)  || `[hidden]`}
                                </div>
                              
                                {/* CONTRIBUTOR */}
                                {display_data?.contributor_level > 0 &&
                                <div className="text-xl font-bold">
                                  <ProfileContributorTag  
                                      showReminder={false}
                                      size={12}
                                    />
                                </div>
                                }

                              </div>

                              {/* USERNAME - BOTTOM (FLAIRS) */}
                              <div className="flex flex-0">

                                <div className="flex items-center content-center">

                                  {/* HOST FLARE */}
                                  {show_flair &&
                                  <>
                                    {display_data?.user_name?.toLowerCase() == profiledata?.user_name?.toLowerCase() &&
                                    <div className="flex-0 mr-1 mb-1">
                                        <FlairPill
                                          color='bg-red-600'
                                          text={'host'}
                                        />
                                    </div>
                                    }

                                    {/* MOD FLARE */}
                                    {bol_mod?.toString() == "1" &&
                                    <div className="flex-0 mr-1">
                                        <FlairPill
                                          color='bg-red-600'
                                          text={'mod'}
                                        />
                                    </div>
                                    }

                                    {/* BOT FLARE */}
                                    {(["server","bot"].indexOf(display_data?.type?.toString()) > -1) &&
                                    <div className="flex-0 mr-1">
                                        <FlairPill
                                          color='bg-green-600'
                                          text={'bot'}
                                        />
                                    </div>
                                    }


                                    {/* CUSTOM FLARE */}
                                    {profiledata?.bol_flair?.toString() == "1" &&
                                    custom_flair?.length > 0 &&
                                    <div className="flex-0 mr-1">
                                        <FlairPill
                                          color='bg-blue-600'
                                          text={truncate(custom_flair,config.chat.flair.maxtaglength,'...')}
                                        />
                                    </div>
                                    }
                                  </>
                                  }

                                </div>

                              </div>

                            </div>
                            }

                          </div>

                          
                    </div>
                    
    </>)
}


export default AuthorCredit