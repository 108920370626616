


const tag_list_array = [{
                                text: "none (don't include)",
                                value:""
                            },
                            {
                                text: "reaction",
                                value:"reaction"
                            },
                            {
                                text: "highlight",
                                value:"highlight"
                            },
                            {
                                text: "soundbyte",
                                value:"soundbyte"
                            },
                            {
                                text: "credit",
                                value:"credit"
                            },
                            {
                                text: "private",
                                value:"private"
                            },
                            {
                                text: "explicit",
                                value:"explicit"
                            },
                            {
                                text: "offensive",
                                value:"offensive"
                            },
                            {
                                text: "other (custom)",
                                value:"other"
                            }
                        ]

export default function TimestampTags({
    tag,
    set_tag,
    show_other,
    set_show_other,
    tag_other,
    set_tag_other,
    tag_index,
    id
}) {

    const cleanTag = (str) => {
        return str.replace(/[^a-z0-9_]/gi, '')?.substr(0,45);
    }

    if (tag_index < id ) return (<></>)
    if (tag_index >= id) {


            return (<div className="mb-2 w-full flex-col">
                        <div>
                    
                        <select
                            className="bg-gray-700 text-white w-full"
                            onChange={(e)=>{
                                set_tag(e.target.value)
                                set_show_other(e.target.value=="other")
                            }}
                            defaultValue={tag}
                        >
                            {tag_list_array.map((e,sindex)=>{
                                return (<option
                                            key={sindex}
                                            value={e.value}
                                            className="bg-gray-700 text-white"
                                        >
                                            {e.text}
                                        </option>)
                            })}
                        </select>
                        </div>
                        
                        {show_other && 
                        <div>
                        <input 
                            type={'text'} 
                            value={tag_other}
                            onChange={(e)=> {
                                set_tag_other(cleanTag(e.target.value))
                               
                            }}
                            className="bg-gray-700 text-white w-full"
                            placeholder="Enter one word, alphanumeric characters and underscores only"
                        />
                        </div>
                        }
                    </div>)
                
            }
}