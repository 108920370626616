import {Fragment} from 'react'
import Link from 'next/link';
import AuthorCredit from '@/components/templateux/user/author-credit';

export default function UsernameSearchResults({
   results
  ,set_results
  ,noresults
  ,closeModal
  ,profiledata
}) {

    return <>
        {/* USER_NAME */}
          <div className="h-64 w-full overflow-y-auto grid gap-y-1">

           {(!results || !(Array.isArray(results[0])) || (Array.isArray(results[0]) && results[0]?.length < 1)) &&
           <>
            <div className="mx-auto flex items-center content-center justify-center">
              <div className="flex-1 text-3xl font-bold">
                {noresults}
              </div>
            </div>
            </>
           }
            
           {results && Array.isArray(results[0]) && results[0]?.map((e)=>{
            return (
              <Fragment
                key={e?.id} 
              
              >
                <Link
                  href={`/${e?.user_name}`}
                  className="flex mr-1 items-center content-center cursor-pointer rounded-md px-2 py-2
                  bg-gray-800  hover:bg-blue-700
                  "
                  onClick={()=>closeModal()}>

                  <AuthorCredit
                    display_data={e}
                    isPlaying={false}
                    profiledata={profiledata}
                  />

                </Link>
              </Fragment>
            );
           })}
           </div>
         

       
     
    </>;
}

