import SettingsSave from "@/components/settings/settings-save"
import SvgJsx from "../svg/svg-jsx"

export default function WizardPager({
     wizard_id
    ,set_wizard_id
    ,submitting
    ,changes
    ,actionText
    ,wizard_array
}) {
    return (<>
              <div className="flex items-center content-center">
              <div className="flex-1 flex items-center content-center ">

                {wizard_id > 0 &&
                <div className='flex-1 mr-2  mt-4'>
                  <div
                      className="inline-flex justify-center p-2  font-medium text-white bg-gray-500 hover:bg-gray-400 cursor-pointer border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      onClick={()=> {
                        set_wizard_id(wizard_id-1)
                      }}
                  >
                    <div className='flex items-center content-center'>
                      <SvgJsx 
                          type={'fill'}
                          icon={'chevron-left-sm'}
                          className={` h-7 w-7`}
                          title={'Previous'}
                      />
                      <div className="mr-2 text-lg">
                        Previous
                      </div>
                    </div>
                    
                  </div>
                </div>
                }

                {wizard_id == 0 &&
                <div className="flex-1"></div>
                }

                {wizard_id < wizard_array.length-1 &&
                <div className="flex-0  mt-4">
                  <div
                      className="inline-flex justify-center p-2 font-medium cursor-pointer border border-transparent rounded-md focus:outline-none
                              
                              text-white bg-blue-600 hover:bg-blue-500 
                      
                      "
                      onClick={()=> {
                        set_wizard_id(wizard_id+1)
                      }}
                  >
                    <span className='block'>
                      <div className="flex items-center content-center">

                        <div className="flex-0 ml-2 text-lg">
                          {wizard_array[wizard_id+1]?.short}
                        </div>
                        <div className="flex-0">
                          <SvgJsx 
                              type={'fill'}
                                  
                              icon={'chevron-right-sm'}
                              className={` h-7 w-7`}
                              title={'Next'}
                          />
                        </div>
                      </div>
                    
                    </span>
                  </div>
                </div>
                }

              {wizard_id == wizard_array.length-1 &&
              <div className="flex-0">
                {/* SUBMIT BUTTON */}
                  <SettingsSave
                      submitting_state={submitting}
                      changes={changes}
                      actionText={actionText}
                      actionTextShort={actionText}
                      className={` whitespace-nowrap text-xl inline-flex items-center justify-center px-4 py-2 border border-red-500 rounded-lg shadow-md  font-medium text-white bg-red-500 hover:bg-red-600 bg-gradient-to-b from-red-600  to-red-500 hover:from-red-700 hover:to-red-500 text-center flex-0`}
                    />
              </div>
              }

              </div>
             
      </div>
    </>)
}