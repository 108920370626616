export default function WizardSection({
    section_id
   ,wizard_id
   ,set_wizard_id
   ,wizard_array
   ,children
  }) {
   return (
   <div className={` ${section_id == wizard_id ? '' : 'hidden'} `}>
     {children}
   </div>)
  }
  
  