import { hasData } from "./hasData"

export function hasDataArrayElement(value,index) {
    let nvalue = value
    if (!hasDataArray(nvalue)) return false

    //parse it, since it's already approved in the hasDataArray function

    if (typeof nvalue === 'string') nvalue = JSON.parse(nvalue)
    

    //check to see if the element exists or not
    if (index > nvalue?.length) return false
    //if it exists, check to see if it has data
    if (!hasData(nvalue[index]?.url)) return false
    return true
}

// export function hasDataArrayOld(value) {

//     let nvalue = value

//     let stringified = JSON.stringify(nvalue);

//     //check to see if the object is null
//     if (!hasData(stringified)) return false

//     //item comes in pre-parsed, but there's a catch in case not

//     //check to see if it is an array
//     if (!Array.isArray(nvalue) && stringified !== "[object Object]" && stringified !== '""' && stringified !== "undefined" && hasData(stringified)) {
//         nvalue = JSON.parse(nvalue)
//     }

//     //check to see the length
//     if (nvalue?.length < 1) return false
//     //check to see if the element exists or not
  
//     return true
// }

export function hasDataArray(value) {
    if (!value) {
      return false;
    }
  
    let parsedValue = value;
    if (typeof value === "string") {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        return false;
      }
    }
  
    if (!Array.isArray(parsedValue)) {
      return false;
    }
  
    return parsedValue.length > 0;
  }