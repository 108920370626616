
import { Dialog, Transition } from '@headlessui/react'
import UsernameSearchInput from "@/components/settings/user/user-name/username-search-input"
import UsernameSearchResults from "@/components/settings/user/user-name/username-search-results"
import { Fragment } from "react"


export default function UsernamesearchModal({
     results
    ,set_results
    ,search
    ,set_search
    ,isOpen
    ,set_isOpen
    ,closeModal
    ,noresults
    ,profiledata
}) {



    
    return (<>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                    as="div"
                    open={isOpen}
                    className="fixed inset-0 z-100 overflow-y-auto bg-[rgb(0,0,0,0.85)]"
                    onClose={closeModal}
                    >
                    
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                       bg-gray-700 
                                        border-gray-700">
                            
                            <div className="sm:flex items-center content-center">
                                <div className="flex-1">
                               
                                    <Dialog.Title
                                    as="h3"
                                    className="text-xl font-bold leading-6 
                                    text-white"
                                    >
                                      
                                      <div className="flex items-center content-center">
                                        {/* <div className="flex-0 mr-2">
                                          <SvgJsx
                                            type="outline"
                                            icon={"search"}
                                            className={`h-8 w-8 `}
                                            title={'Search'}
                                          />
                                        </div> */}
                                        <div className="flex-1">
                                        <>
                                        <UsernameSearchInput 
                                          results={results}
                                          set_results={set_results}
                                          search={search}
                                          set_search={set_search}
                                        />
                                        </>
                                        </div>
                                      </div>
                                           
                                    </Dialog.Title>
                                </div>
                                
                            </div>
                            
                            
                            
                            <div className="mt-4 w-full content-center item-center flex">

                           

                            
                               
                                <>
                                <UsernameSearchResults
                                  results={results}
                                  set_results={set_results}
                                  noresults={noresults}
                                  closeModal={closeModal}
                                  profiledata={profiledata}
                                />
                                </>
                               
                           
                            </div>
                            

                            <div className="mt-4 border-t pt-4 
                                            border-gray-700
                            ">
                            <div className="flex items-center content-center">
                                <div className="flex-1">
                                    <></>
                                </div>
                                <div className='flex-0'>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 mt-5text-xl font-medium text-white bg-red-500 hover:bg-red-400  border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                           
                            </div>
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>
    </>)
}