import LoadingInner from "./loading-inner"

export const LoadingProcessing = () => {

    return (<div className='w-full h-64 flex items-center content-center'>
            <div className='text-center w-full'>
            <div className="flex  ">
                <div className="m-auto">
                <h3 className="text-md sm:text-lg">
                    <LoadingInner
                        showRandom={true}
                        
                        classSize='w-16 h-16 sm:w-24 sm:h-24'
                    />
                </h3>
                </div>
            </div>
            </div>
        </div>)
}