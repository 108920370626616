import { config, listen_experience_array, player_style } from '@/lib/config'
import { hasData } from '@/lib/utils/hasData'
import Image from 'next/legacy/image'
import { memo, useContext, useEffect, useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import SvgJsx from '../templateux/svg/svg-jsx'
import { PlayerContext } from './player-wrapper'
import Slider from 'react-input-slider';
import Link from 'next/link'
import { useSWRWrapper } from '@/lib/utils/hooks/swr-hooks'
import { useConfirm } from '@/lib/utils/hooks/useConfirm'
import { useStopwatch } from 'react-timer-hook'
import { padZero } from '@/lib/utils/padZero'
import { PushContext } from '../context/push-wrapper'
import PlayerModal from './player-modal'
import { ifNull } from '@/lib/utils/ifNull'
import { TribeContext } from '../context/tribe-wrapper';

 function PlayerNavLiveNested({
  open
 ,set_open
 ,userdata
}) {

  
  const ssr_data_tribe = useContext(TribeContext)

    const {player_state,set_player_state} = useContext(PlayerContext)
    const channel_id = `${ssr_data_tribe?.brand_id}-${config.websockets.listen}${player_state.user_id}`
    const [isMounted,set_isMounted] = useState(false)
    const [isModalOpen,set_isModalOpen] = useState(false)
    const [clock,set_clock] = useState(`00:00:00`)
    
    useEffect(()=>{
        set_isMounted(true);
        return () => {};
    },[]);

    // const [show_player,set_show_player] = useState(false)
    // useEffect(()=>{
    //   if (hasData(player_state.file)) set_show_player(true)
    // },[
    //   player_state.file
    // ])

    const doFidelity = (listen_experience) => {

      //HIFI CHECK
      if (listen_experience?.toString()=="2" && userdata?.contributor_level < 1) {
        alert('This live stream quality level is only available for contributors.')
        return
      } 

      //MID CHECK
      if (listen_experience?.toString()=="1" && userdata?.id?.toString() == "0") {
        alert('This live stream quality level is only available for registered members. Create an account and try again.')
        return
      } 

      //Good to continue
      set_player_state({...player_state
        ,listen_experience: listen_experience
      });
      return 
      
    }

    const onMount = async(channel,userdata) => {
        
        // channel.presence.subscribe('enter', function(member) {
        //   channel.presence.get(function(err, data) {
        //   });
        // });

        // channel.presence.subscribe('update', function(member) {
        //   channel.presence.get(function(err, data) {
        //   });
        // });
    
        // channel.presence.subscribe('leave', function(member) {
        //   channel.presence.get(function(err, data) {});
        // });
    
        var custom_flair = ''
    
        channel.presence.update({
                                 id: userdata?.id
                                ,user_name: userdata?.user_name
                                ,avatar_url: userdata?.avatar_url
                                ,avatar_url_sfw: userdata?.avatar_url_sfw
                                ,avatar_crdate: userdata?.avatar_crdate
                                ,bol_nsfw: userdata?.bol_nsfw
                                ,contributor_level: userdata?.contributor_level
                                //,verified: userdata?.verified
                                ,custom_flair: custom_flair
                                ,listen: true
                              });
    
      }
    
      const onUnmount = (channel) => {
    
        channel.presence.leave();
        channel.presence.unsubscribe();
        channel.unsubscribe();
      }
    
  const ably = useContext(PushContext);

  useEffect(() => {
    if (userdata && !userdata.isLoading) {
      onMount(ably?.channels?.get(channel_id),userdata);
    }
  }, [userdata]);

  useEffect(() => {
    return () => {
      onUnmount(ably?.channels?.get(channel_id));
    }
  }, []);









  const closeModal = () => {
    set_isModalOpen(false)
}









      //TIMER FUNCTIONS
      const startTime = +new Date(player_state?.stream_start_date); 
      const now = +new Date(); //now
      const stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + (now-startTime)/1000);
      const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
      } = useStopwatch({ autoStart: true,offsetTimestamp: stopwatchOffset});

    const { isConfirmed } = useConfirm();
    const popupref = useRef(null);
    const playerRef = useRef(null);


    //PLAYER DATA TO SEE IF SELECTED BROADCAST HAS GONE OFFLINE
    const { 
      data: playerdata
    , loading: playerdataisLoading
    , error: playerdataisError
                    } = useSWRWrapper(
                        player_state?.player_type?.toString() == "live" 
                        && !player_state?.isEnded
                        && hasData(player_state?.content_id?.toString()) 
                        && player_state?.content_id?.toString() !== "0" 
                        && player_state?.content_id?.toString() //id
                      ,`/api/public/v0/broadcast/status?id=${player_state?.content_id}` //key
                      ,true //retry?
                      ,config.max_retries //max number of retries
                      ,config.retry_interval //retry interval
                      ,config.profile.stream_interval_host //refreshinterval (0=none)
                      )

    useEffect(()=>{
      if (!playerdataisLoading && !playerdataisError && playerdata){
        // console.log("playerdata",playerdata)

        //start the broadcast
        if ( playerdata?.stream_status?.toString() == "1" 
            && player_state?.broadcast_status?.toString() == "0") {

              //set state
              set_player_state({...player_state
                ,broadcast_status: playerdata?.stream_status
                ,isEnded: false
                ,isPlaying: true
              })

              // //start the timer
              if (!isRunning) start();
        }

        //end the broadcast
        if ( playerdata?.stream_status?.toString() == "0" 
            && player_state?.broadcast_status?.toString() == "1") {

              //reset state
              set_player_state({...player_state
                ,broadcast_status: playerdata?.stream_status
                ,isEnded: true
                ,isPlaying: false
              })

              //clear the timer
              if (isRunning) pause()
        }

        //update the broadcast title (if host changed it)
        if ( (playerdata?.broadcast_id?.toString() == player_state?.content_id?.toString() && player_state?.content_table?.toString() == 'broadcast')
          &&  playerdata?.broadcast_title?.toString() !== player_state?.title?.toString()) {

              //reset state
              set_player_state({...player_state
                ,title: playerdata?.broadcast_title
              })
        }
        
      }
    },[
       playerdata
      ,playerdataisLoading
      ,playerdataisError
      ,player_state
      ,set_player_state
    ])




  const closeMenu = () => {if (popupref.current) popupref.current.close()};

  const formatMonthDay = (this_date) => {

    let vdate = new Date(this_date)

    return `${vdate?.getMonth()+1}/${vdate?.getDate()}`
  }

    

    return <>
          
           {player_state.isHide == "0" &&
            <div className={`flex-1 flex items-center content-center`}>

              
              <div className='flex-0 ml-2 flex items-center content-center'>
                <div 
                  className={`flex-0 `}
                  
                >

                  <div className=" flex-0 menu flex items-center content-center relative ">
                    <Popup
                      trigger={ (isOpen)=>
                        <div 
                          className={`flex-0  shrink-0 flex items-center content-center p-0 pl-0.5 cursor-pointer
                                    rounded-l-full 
                                    ${player_state?.broadcast_status?.toString() == "1" 
                                      ? "bg-red-600 hover:bg-red-500 border-red-700 text-white"
                                      : ` bg-gray-500 text-gray-200`
                                    }
                                    `}
                          onClick={()=> {
                            set_player_state({...player_state,isPlaying:!player_state.isPlaying});
                            open !== 'player' ? set_open('player') : set_open('');
                          }}
                        >
                          <div className="flex-0 flex items-center content-center  shrink-0 rounded-full" 
                           style={
                          //LIVE
                            (player_state?.player_type == 'live'
                            && player_state?.broadcast_status?.toString() == "1")
                          //FILE
                          || (player_state?.player_type == 'file' 
                              && player_state.isPlaying) 
                              ? {
                                  backgroundImage: `url("/images/app/live-64x64.gif")`
                                  , backgroundRepeat: `no-repeat`
                                  ,backgroundSize: `cover`
                                  ,backgroundPosition: `center`
                                }
                              : {
                                backgroundColor: `#fff`
                              }
                          }
                          >
                            
                              <Image
                                src={hasData(player_state?.avatar_url) ? player_state?.avatar_url : config.avatar.url_missing}
                                alt={player_state?.user_name}
                                className={`h-7 w-7 rounded-full shrink-0 bg-white object-cover
                                          ${//LIVE
                                            (player_state?.player_type == 'live'
                                             && player_state?.broadcast_status?.toString() == "1")
                                            //FILE
                                            || (player_state?.player_type == 'file' 
                                                && player_state.isPlaying)
                                              ? "animate-spin-slow" 
                                              : ""}
                                          `}
                                width={24}
                                height={24}
                                quality={100}
                              />
                            
                          </div>
                          <div className="flex-0 ml-1 flex items-center content-center">
                              <div className="flex-0 uppercase ml-0.5 mr-2 w-24 flex items-center content-center h-7">
                                 <div className="flex items-center content-center w-full ">
                                    <div className="flex-1 flex items-center content-center">
                                      <span className=" flex-0 text-sm whitespace-nowrap  font-bold ">
                                        {player_state?.broadcast_status?.toString() == "1" 
                                          ? `LIVE`
                                          : 'OFF AIR'
                                        }
                                      </span>
                                      {player_state?.broadcast_status?.toString() == "1" 
                                      ? <span>
                                          <div className="font-regular text-xs ml-2">
                                            <span>{padZero(hours)}</span>:
                                            <span>{padZero(minutes)}</span>:
                                            <span>{padZero(seconds)}</span>
                                          </div>
                                        </span>
                                      : <></>
                                      }

                                    </div>

                                    {/* {player_state?.broadcast_status?.toString() == "1" &&
                                    <div className="ml-2 flex-0 flex items-center content-center font-thin text-2xs py-0.5 text-white bg-red-800 rounded-full pl-1 px-2">
                                      <div className="flex-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-2.5 w-2.5" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                                        </svg>
                                      </div>
                                      <div className="flex-0 ml-0.5">
                                      {player_state?.current_listeners}
                                      </div>
                                      <div className="flex-0 ml-1.5">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-2.5 h-2.5">
                                          <path fillRule="evenodd" d="M3.43 2.524A41.29 41.29 0 0110 2c2.236 0 4.43.18 6.57.524 1.437.231 2.43 1.49 2.43 2.902v5.148c0 1.413-.993 2.67-2.43 2.902a41.102 41.102 0 01-3.55.414c-.28.02-.521.18-.643.413l-1.712 3.293a.75.75 0 01-1.33 0l-1.713-3.293a.783.783 0 00-.642-.413 41.108 41.108 0 01-3.55-.414C1.993 13.245 1 11.986 1 10.574V5.426c0-1.413.993-2.67 2.43-2.902z" clipRule="evenodd" />
                                        </svg>

                                      </div>
                                      <div className="flex-0 ml-0.5">
                                      {player_state?.current_chatters}
                                      </div>
                                          
                                    </div>
                                    } */}

                                 </div>
                                  
                              </div>
                              <div className="hidden xs:flex-0 ml-2 items-center content-center">
                                  <SvgJsx 
                                      type={'fill'}
                                      icon={"chevron-down-sm"}
                                      className={`h-5 w-5  `}
                                      title={"open menu"}
                                  />
                              </div>
                              
                            </div>
                        </div>


                        }
                      position="bottom left"
                      on="click"
                      open={open}
                      onOpen={()=>set_open('player')}
                      closeOnDocumentClick
                      closeOnEscape
                      mouseLeaveDelay={300}
                      mouseEnterDelay={0}
                      contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1999 }}
                      arrow={false}
                      nested
                      ref={popupref}
                    >
                      <div className={`menu fixed top-[45px] left-0 sm:left-auto sm:w-64 w-full flex flex-col gap-y-1 rounded-md shadow-xl p-2 mt-0.5
                                    
                                    ${player_state?.broadcast_status?.toString() == "1" 
                                      ? `bg-red-600 text-white`
                                      : `bg-gray-500 text-gray-200`
                                      
                                    }
                                    
                                    `}
                      >

                        
                        <div>
                          {/* INFO */}
                         
                          <div className=''>
                           Title: 
                           <Link
                             href={`/${player_state?.user_name?.toLowerCase()}/chat`}
                             className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0">

                             {hasData(player_state?.title) ? player_state?.title : `Untitled ${formatMonthDay(player_state?.stream_start_date)} Show`}

                           </Link>
                          </div>
                          <div className=''>
                           Host: 
                           <Link
                             href={`/${player_state?.user_name?.toLowerCase()}`}
                             className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0">

                             {player_state?.user_name}

                           </Link>
                          </div>
                          <div className=''>
                           Type: Broadcast
                          </div>
                          
                          <div className='flex'>
                          Duration:  

                                        <span className="ml-2">
                                          <span>{padZero(hours)}</span>:
                                          <span>{padZero(minutes)}</span>:
                                          <span>{padZero(seconds)}</span>
                                        </span>

                          
                          </div>
                          <div className="mt-1 hidden">
                            <button
                              className="border rounded-md border-white px-2 py-1"
                              onClick={()=> 
                                {
                                  set_isModalOpen(true);
                                  set_clock(`${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`)
                                }}
                            >
                              Save timestamp
                            </button>
                          </div>
                          {/* <div>
                            Current Listeners: {player_state?.current_listeners}
                          </div>
                          <div>
                            Current Chatters: {player_state?.current_chatters}
                          </div> */}

                          

                          {/* STATUS */}
                          <div className='hidden'>
                           Status: {player_state?.isMediaLoaded 
                                    ? "Loaded"
                                    : player_state?.isBuffering
                                      ? "Buffering"
                                      : "Error"
                           
                          }
                          </div>
                          
                        </div>

                        {/* <div className='flex items-center content-center'>
                            Quality:
                            <select 
                              className={`
                              ${player_state?.broadcast_status?.toString() == "1" 
                                          ? `bg-red-600 text-white`
                                          : `bg-gray-500 text-gray-200`}
                              border border-white rounded-md ml-1 text-xs py-0.5 `} 
                              onChange={(e)=>doFidelity(e.target.value)}
                              value={player_state?.listen_experience}
                            >
                            {listen_experience_array.map((e,index)=> {
                              
                              if (e.premium==0) return (<option
                                                          key={index}
                                                          value={e.value}
                                                        >{e.text}</option>)
                              
                            })}
                            </select>
                        </div> */}



                     


                      {/* PLAY BUTTONS */}

                      {/* LIVE */}
                      {player_state?.player_type == "live" &&
                      <div 
                        className={`group flex items-center content-center text-center my-5 border-t justify-center pt-4 
                        ${player_state?.broadcast_status == "1" 
                          ? 'cursor-pointer'
                          : ''
                        }

                        ${player_state?.broadcast_status?.toString() == "1" 
                        ? `border-red-900`
                        : `border-gray-600`
                        }
                        
                        `}
                        onClick={()=> {
                          if (player_state?.broadcast_status == "1") {
                           set_player_state({...player_state
                          ,isPlaying:!player_state?.isPlaying
                          })
                        } else {
                          alert("The show is currently OFF AIR and cannot be played.")
                        }
                      }}
                      >
                        
                        <div 
                          className="flex-0  opacity-100 group-hover:opacity-90"
                          
                        >
                          <SvgJsx 
                              type={'fill'}
                              icon={ player_state?.broadcast_status == "0"
                                    ? "stop-sm"
                                    : player_state?.isPlaying
                                      ? "pause-sm"
                                      : "play-sm"}
                              className={`h-24 w-24  mx-auto `}
                              title={ player_state?.broadcast_status == "0"
                              ? "stop"
                              : player_state?.isPlaying
                                ? "pause"
                                : "play" }
                          />
                        </div>
                        <div className="flex-1 font-bold my-auto text-2xl flex items-center ml-2 opacity-100 group-hover:opacity-90">
                          <div>
                          {  player_state?.broadcast_status == "0"
                            ? "OFF AIR"
                            : player_state?.isPlaying 
                                ? "Listening" 
                                :  player_state?.isLoading
                                  ? `Loading`
                                  : "Stopped"}
                           
                              
                          </div>
                          
                        </div>
                        
                      </div>
                      }

                      {/* VOLUME */}
                      <div className={`flex items-center content-center border-t pt-4
                        
                        ${player_state?.broadcast_status?.toString() == "1" 
                              ? `border-red-900`
                              : `border-gray-600`
                          }
                      `}>

                        <div 
                          className={`flex-0 cursor-pointer opacity-100 hover:opacity-75
                                    ${player_state?.isMuted ? ' animate-pulse' : ''}
                          `}
                          onClick={()=> {
                            set_player_state({...player_state
                            ,isMuted:!player_state?.isMuted
                    })}}
                        >
                          <SvgJsx 
                              type={'fill'}
                              icon={player_state?.isMuted ? "volume-off-sm" : "volume-up-sm"}
                              className={`h-4 w-4  `}
                              title={player_state?.isMuted ? "Muted" : "Unmuted"}
                          />
                        </div>
                        <div className="flex-1 ml-2 text-sm px-2">
                        <Slider
                                axis='x'
                                x={(player_state?.volume_slider)}
                                xmax={100}
                                disabled={player_state?.isMuted}
                                onChange={({ x }) => {
                                        set_player_state({...player_state
                                                ,volume:x/100
                                                ,volume_slider:x
                                        });
                                        localStorage.setItem('volume_slider',x?.toString());
                                    }}
                                
                                styles={player_style(player_state?.isPlaying)}
                                />
                        </div>
                        <div 
                          className="flex-0 ml-3 w-8 text-left text-xs cursor-pointer  opacity-100 hover:opacity-75"
                          onClick={()=> {
                            set_player_state({...player_state
                                ,volume: 1
                                ,volume_slider: 100
                                ,isMuted: false
                            })
                          }}
                        >
                          {player_state?.isMuted ? "0": Math.round(player_state?.volume*100)}%
                        </div>
                        
                      </div>

                      </div>
                    </Popup>
                  </div>



                  
                </div>
                {/* PLAY/PAUSE */}
                <div 
                  className={`flex-0 rounded-r-full flex items-center content-center font-bold p-0 h-7


                            ${ player_state?.broadcast_status == "1"
                              ? 'cursor-pointer' 
                              : ''
                            }
                            
                              ${player_state?.broadcast_status?.toString() == "1" 
                              ? `bg-red-600 hover:bg-red-500   text-white`
                              : `bg-gray-500 text-gray-200 border-gray-700`
                              }
                            `}
                  onClick={()=> {

                    if (player_state?.broadcast_status == "1") {
                      set_player_state(
                        {...player_state
                          ,isPlaying: !player_state.isPlaying
                          ,isHide: "0"
                        })
                    } else {
                      set_player_state(
                        {...player_state
                          ,isPlaying: false
                          ,isHide: "0"
                        })
                      alert('This broadcast is currently OFF AIR and cannot be played.')
                    }
                   
                  }}
                >
                  <div className="flex-0 flex items-center content-center">
                     
                     
                      <div className="flex-0 p-0 pr-0.5">
                        <SvgJsx 
                            type={'fill'}
                            icon={
                              player_state?.broadcast_status == "0"
                              ? "stop-large-sm"
                              : player_state.isPlaying
                                ? "pause-large-sm"
                                : "play-large-sm" 
                            }
                            className={`h-6 w-6 p-1 
                              ${player_state?.broadcast_status == "0" 
                              ? "mt-1" 
                              : "mt-0.5"}
                            
                            ${player_state.isPlaying || player_state?.broadcast_status?.toString() == "0"  
                              ? '' : 
                              'animate-pulse'}
                            `}
                            title={player_state?.broadcast_status == "0"
                                  ? "stop"
                                  : player_state.isPlaying
                                    ? "pause" 
                                    : "play" 
                            }
                        />
                      </div>
                      
                      
                    </div>
                </div>

                
              </div>

              {/* CLOSE */}
              <div 
                className={`flex-0  flex items-center content-center font-bold p-0 cursor-pointer ml-1
                          
                         text-gray-600 hover:text-gray-400`}
                onClick={async ()=>{
                  
                  closeMenu();
                  const confirmed = await isConfirmed('Are you sure you want to close the player?');
                  
                  if (confirmed) {


                    // let volume = player_state?.volume;
                    // let volume_slider = player_state?.volume_slider;

                    // set_player_state(
                    //   {...player_state
                    //     ,isPlaying: false
                    //     // ,broadcast_status:player_state?.broadcast_status
                    //     // ,volume: volume
                    //     // ,volume_slider: volume_slider
                    //   })

                    // set_show_player(false);

                    await pause(); //stop the timer
                    //await fetch(`/api/public/v0/broadcast/listen?id=${player_state?.content_id}&host=${player_state?.user_id}&type=0&origin=local&listener=${player_state?.listener}`);
                    //await location.reload()
                    // await set_player_state({     
                    //    volume: volume
                    //   ,volume_slider: volume_slider
                    //   ,isPlaying: false
                    // });

                    // set_listener(null);
                    // set_player_state(
                    //   {...player_state
                    //   ,file:''
                    //   ,title:''
                    //   ,seek:0
                    //   ,seek_ratio:0
                    //   ,seek_slider:0
                    //   ,length:0
                    //   ,user_id:0
                    //   ,user_name:''
                    //   ,content_id:0
                    //   ,content_id_last:0
                    //   ,content_table:''
                    //   ,avatar_url:''
                    //   ,playerRef: playerRef}
                    // );

                 

                    await set_player_state(prev=>{
                      return {...prev
                        ,isPlaying: false
                        ,isHide: "1"
                        //,file: ''
                        // ,content_id:0
                        // ,content_id_last:0
                        // ,content_table:''
                      }
                    })


                    
                  }
                }}
              >
                <div className="flex-0 flex items-center content-center">
                    
                    <div className="flex-0">
                      <SvgJsx 
                          type={'fill'}
                          icon={"x-sm"}
                          className={`h-4 w-4  `}
                          title={"close"}
                      />
                    </div>
                    
                  </div>
              </div>


              {/* MESSAGE */}
              <div className='flex-1 ml-2 text-3xs'>
                  
                  {/* {!player_state?.isMediaLoaded && 
                  <>Loading</>
                  } */}
                 
              </div>



            </div>
          }
            
            <PlayerModal
                modal_panel={{id:"timestamp"}}
                isModalOpen={isModalOpen}
                clock={clock}
                set_clock={set_clock}
                closeModal={closeModal}
                sendmsg={()=>{}}
                channel_id={''}
                host_id={player_state?.user_id}
                post_id={ifNull(player_state?.post_id,0)}
                content_id={player_state?.content_id}
              />
    </>;
}

export const PlayerNavLive = (PlayerNavLiveNested)