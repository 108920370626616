export const padZero = (n) => {
    if (n < 10) {
      return `0${n}`
    } else {
      return `${n}`
    }
  }


export const  padZeroVariable = (n, length_size,pad_char) => {
  
  if (String(n).length < length_size) {
    return String(n).padStart(length_size-String(n).length+1, pad_char);
  }
  return String(n);
}