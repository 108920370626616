import FormInput from "@/components/templateux/form-input/form-input";
import { config } from "@/lib/config";

export default function PostFormType({
     _post_type
    ,set_post_type
    ,_post_contents_json
    ,set_post_contents_json
    ,_post_body
    ,set_post_body
    ,submitting
    ,set_submitting
    ,processing
    ,set_processing
    ,changes
    ,set_changes
    ,index=0
    ,max_files=config.post.max_files
    ,isEditable=true
    ,identifier=`container`
}) {
    

    return (<>

        

        {/* <div>

            <FormInput 
              type={'selectbox'}
              title={`Attachment Type ${index}`}
              description={''}
              helptip={''}
              name={'post_type'}
              placeholder={''}
              options={[
                lookupPost("text",-1),
                lookupPost("image",-1),
                lookupPost("audio",-1),
                lookupPost("mic",-1)
              ]}
        
              value={_post_type}
              set_value={set_post_type}
              hidden_value={_post_contents_json}
              set_hidden_value={set_post_contents_json}

              //submitting
              submitting={submitting}
              set_submitting={set_submitting}
              set_changes={set_changes}
              changes={changes}
              processing={processing}
              set_processing={set_processing}
              index={index}
              isEditable={isEditable}
            identifier={identifier}
            />

        </div> */}

        <div>
          
        {/* {_post_type=='0' &&
          <>
         
          <div>

            <FormInput 
              type={'textarea-autosize'}
              title={'Post Body Text'}
              description={''}
              helptip={''}
              name={'post_body'}
              placeholder={''}
              options={[{minRows:8}]}
              
              value={_post_body}
              set_value={set_post_body}

              //submitting
              submitting={submitting}
              set_submitting={set_submitting}
              set_changes={set_changes}
              changes={changes}
              processing={processing}
              set_processing={set_processing}
              index={index}
              isEditable={isEditable}
            identifier={identifier}
            />
          </div>
          </>
          } */}

          {_post_type?.toString()=='1' &&
          <>
          
          <div>
          <FormInput 
            type={'image'}
            title={''}
            description={''}
            helptip={''}
            name={'post_contents_json'}
            placeholder={''}
            options={[{max_files:max_files}]}
            
            value={_post_contents_json}
            set_value={set_post_contents_json}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
            processing={processing}
            set_processing={set_processing}
            
            index={index}
            isEditable={isEditable}
            identifier={identifier}
          />
          </div>
          </>
          }

          {_post_type?.toString()=='4' &&
          <>
          
          <div>
          <FormInput 
            type={'avatarupload'}
            title={''}
            description={''}
            helptip={''}
            name={'post_contents_json'}
            placeholder={''}
            options={[{max_files:max_files}]}
            
            value={_post_contents_json}
            set_value={set_post_contents_json}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
            processing={processing}
            set_processing={set_processing}
            index={index}
            isEditable={isEditable}
            identifier={identifier}
          />
          </div>
          </>
          }

          {["2","5","13"].indexOf(_post_type?.toString()) > -1 &&
          <>
          <div>
          <FormInput 
            type={'audio'}
            title={''}
            description={''}
            helptip={''}
            name={'post_contents_json'}
            placeholder={''}
            options={[{max_files:max_files}]}
            
            value={_post_contents_json}
            set_value={set_post_contents_json}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
            processing={processing}
            set_processing={set_processing}
            index={index}
            isEditable={isEditable}

            identifier={identifier}
          />
          </div>
          </>
          }

          {_post_type?.toString()=='3' &&
          <>
          <div>
          <FormInput 
            type={'mic'}
            title={''}
            description={'Hit the record button to begin recording. You may need to grant the browser permission. Hit stop when done.'}
            helptip={''}
            name={'post_contents_json'}
            placeholder={''}
            options={[{max_files:max_files}]}
            
            value={_post_contents_json}
            set_value={set_post_contents_json}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
            processing={processing}
            set_processing={set_processing}
            index={index}
            isEditable={isEditable}
            identifier={identifier}
          />
          </div>
          </>
          }


         {_post_type=='14' && //youtube video urls
          <>
         
          <div>
            <FormInput 
              type={'text'}
              title={'Video URL'}
              description={'Enter the video of the YouTube link you are posting'}
              helptip={''}
              name={'post_body'}
              placeholder={'https://www.youtube.com/watch?v=abcdefghijklmnop'}
              options={[]}
              
              value={_post_body}
              set_value={set_post_body}

              //submitting
              submitting={submitting}
              set_submitting={set_submitting}
              set_changes={set_changes}
              changes={changes}
              processing={processing}
              set_processing={set_processing}
              index={index}
              isEditable={isEditable}
              identifier={identifier}
            />
          </div>
          </>
          } 


        </div>
    
    </>)
}